import { call, put, takeLatest } from "redux-saga/effects";
import { fetchIncomeReports as fetchIncomeReportsApi } from "../apis/incomeApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { incomeSlice } from "../slices/incomeSlice";

const incomeActions = incomeSlice.actions;

export function* fetchIncomeReports(actions) {
	try {
		const response = yield call(fetchIncomeReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			incomeActions.fetchIncomeReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Income Reports",
			"Failed to fetch income reports!",
		);
		yield put(incomeActions.fetchIncomeReportsFailed());
	}
}

export function* watchFetchIncomeReports() {
	yield takeLatest(incomeActions.fetchIncomeReports.type, fetchIncomeReports);
}
