import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addFarm as addFarmApi,
  fetchFarms as fetchFarmsApi,
  updateFarm as updateFarmApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { farmSlice } from "./slice";

const farmActions = farmSlice.actions;

export function* addFarm(actions) {
  try {
    const { name, location } = actions.payload;

    const response = yield call(addFarmApi, {
      name,
      location,
      user: getUser().id,
    });
    const resp = response && response.data;

    yield put(farmActions.addFarmSuccess(resp));
    OpenNotificationWithIcon("success", "Add Farm", "Farm Added Successful!");
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) || "Failed to add farm";
    yield put(farmActions.addFarmFailed(message));
  }
}

export function* updateFarm(actions) {
  try {
    const { id, name, location } = actions.payload;

    const response = yield call(updateFarmApi, id, {
      name,
      location,
      user: getUser().id,
    });
    const resp = response && response.data;

    yield put(farmActions.updateFarmSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Farm",
      "Farm updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update farm";
    yield put(farmActions.updateFarmFailed(message));
  }
}

export function* fetchFarms(actions) {
  try {
    const response = yield call(fetchFarmsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      farmActions.fetchFarmsSuccess({
        results: resp && resp.results,
        count: resp && resp.count,
      }),
    );
  } catch (e) {
    OpenNotificationWithIcon("error", "Fetch Farms", "Failed to fetch farms!");
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to add farm";
    yield put(farmActions.fetchFarmsFailed(message));
  }
}

export function* watchAddFarm() {
  yield takeLeading(farmActions.addFarm.type, addFarm);
}

export function* watchUpdateFarm() {
  yield takeLeading(farmActions.updateFarm.type, updateFarm);
}

export function* watchFetchFarms() {
  yield takeLatest(farmActions.fetchFarms.type, fetchFarms);
}
