import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addWeaning as addWeaningApi,
  updateWeaning as updateWeaningApi,
  fetchWeanings as fetchWeaningsApi,
} from "../apis/weaningApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { weaningSlice } from "../slices/weaningSlice";

const goatActions = weaningSlice.actions;

export function* addWeaning(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addWeaningApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addWeaningSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Weaning",
      "Goat Weaning Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add weaning";
    yield put(goatActions.addWeaningFailed(message));
  }
}

export function* updateWeaning(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateWeaningApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateWeaningSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Weaning",
      "Goat Weaning Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update weaning";
    yield put(goatActions.updateWeaningFailed(message));
  }
}

export function* fetchWeanings(actions) {
  try {
    const response = yield call(fetchWeaningsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchWeaningsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Weaning",
      "Failed to fetch goat weaning!",
    );
    yield put(goatActions.fetchWeaningsFailed());
  }
}

export function* watchAddWeaning() {
  yield takeLeading(goatActions.addWeaning.type, addWeaning);
}

export function* watchUpdateWeaning() {
  yield takeLeading(goatActions.updateWeaning.type, updateWeaning);
}

export function* watchFetchWeanings() {
  yield takeLatest(goatActions.fetchWeanings.type, fetchWeanings);
}
