import { call, put, takeLatest } from "redux-saga/effects";
import { fetchPurchaseReports as fetchPurchaseReportsApi } from "../apis/purchaseApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { purchaseSlice } from "../slices/purchaseSlice";

const purchaseActions = purchaseSlice.actions;

export function* fetchPurchaseReports(actions) {
	try {
		const response = yield call(fetchPurchaseReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			purchaseActions.fetchPurchaseReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Purchase Reports",
			"Failed to fetch purchase reports!",
		);
		yield put(purchaseActions.fetchPurchaseReportsFailed());
	}
}

export function* watchFetchPurchaseReports() {
	yield takeLatest(
		purchaseActions.fetchPurchaseReports.type,
		fetchPurchaseReports,
	);
}
