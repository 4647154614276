import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addFeedOutState: { feedout: null, isloading: "", errors: null },
  updateFeedOutState: { feedout: null, isloading: "", errors: null },
  fetchFeedOutsState: { feedouts: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const feedoutSlice = createSlice({
  name: "feedout",
  initialState: initialState,
  reducers: {
    addFeedOut: (state) => {
      state.addFeedOutState.isloading = "processing";
      state.addFeedOutState.errors = null;
    },
    addFeedOutSuccess: (state, action) => {
      state.addFeedOutState.isloading = "success";
      state.addFeedOutState.feedout = action.payload;
    },
    addFeedOutFailed: (state, action) => {
      state.addFeedOutState.isloading = "failed";
      state.addFeedOutState.errors = action.payload;
    },
    updateFeedOut: (state) => {
      state.updateFeedOutState.isloading = "processing";
      state.updateFeedOutState.errors = null;
    },
    updateFeedOutSuccess: (state, action) => {
      state.updateFeedOutState.isloading = "success";
      state.updateFeedOutState.feedout = action.payload;
    },
    updateFeedOutFailed: (state, action) => {
      state.updateFeedOutState.isloading = "failed";
      state.updateFeedOutState.errors = action.payload;
    },
    fetchFeedOuts: (state) => {
      state.fetchFeedOutsState.isloading = "processing";
      state.fetchFeedOutsState.errors = null;
    },
    fetchFeedOutsSuccess: (state, action) => {
      console.log("fetchFeedOutsSuccess action is ", action);
      state.fetchFeedOutsState.isloading = "success";
      state.fetchFeedOutsState.feedouts = action.payload;
    },
    fetchFeedOutsFailed: (state, action) => {
      state.fetchFeedOutsState.isloading = "failed";
      state.fetchFeedOutsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddFeedOut(state) {
      state.addFeedOutState = { feedout: null, isloading: "", errors: null };
    },
    resetUpdateFeedOut(state) {
      state.updateFeedOutState = { feedout: null, isloading: "", errors: null };
    },
  },
});

export { feedoutSlice };
