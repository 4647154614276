import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchTreatmentReportsState: {
    treatmentReports: [],
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const treatmentSlice = createSlice({
  name: "treatmentReports",
  initialState: initialState,
  reducers: {
    fetchTreatmentReports: (state) => {
      state.fetchTreatmentReportsState.isloading = "processing";
      state.fetchTreatmentReportsState.errors = null;
    },
    fetchTreatmentReportsSuccess: (state, action) => {
      console.log("fetchTreatmentReportsSuccess action is ", action);
      state.fetchTreatmentReportsState.isloading = "success";
      state.fetchTreatmentReportsState.treatmentReports = action.payload;
    },
    fetchTreatmentReportsFailed: (state, action) => {
      state.fetchTreatmentReportsState.isloading = "failed";
      state.fetchTreatmentReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { treatmentSlice };
