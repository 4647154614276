import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGoatBirthState: { birth: null, isloading: "", errors: null },
  updateGoatBirthState: { birth: null, isloading: "", errors: null },
  fetchGoatBirthsState: { births: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const birthSlice = createSlice({
  name: "birth",
  initialState: initialState,
  reducers: {
    addGoatBirth: (state) => {
      state.addGoatBirthState.isloading = "processing";
      state.addGoatBirthState.errors = null;
    },
    addGoatBirthSuccess: (state, action) => {
      state.addGoatBirthState.isloading = "success";
      state.addGoatBirthState.birth = action.payload;
    },
    addGoatBirthFailed: (state, action) => {
      state.addGoatBirthState.isloading = "failed";
      state.addGoatBirthState.errors = action.payload;
    },
    updateGoatBirth: (state) => {
      state.updateGoatBirthState.isloading = "processing";
      state.updateGoatBirthState.errors = null;
    },
    updateGoatBirthSuccess: (state, action) => {
      state.updateGoatBirthState.isloading = "success";
      state.updateGoatBirthState.birth = action.payload;
    },
    updateGoatBirthFailed: (state, action) => {
      state.updateGoatBirthState.isloading = "failed";
      state.updateGoatBirthState.errors = action.payload;
    },
    fetchGoatBirths: (state) => {
      state.fetchGoatBirthsState.isloading = "processing";
      state.fetchGoatBirthsState.errors = null;
    },
    fetchGoatBirthsSuccess: (state, action) => {
      console.log("fetchGoatBirthsSuccess action is ", action);
      state.fetchGoatBirthsState.isloading = "success";
      state.fetchGoatBirthsState.births = action.payload;
    },
    fetchGoatBirthsFailed: (state, action) => {
      state.fetchGoatBirthsState.isloading = "failed";
      state.fetchGoatBirthsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoatBirth(state) {
      state.addGoatBirthState = { birth: null, isloading: "", errors: null };
    },
    resetUpdateGoatBirth(state) {
      state.updateGoatBirthState = { birth: null, isloading: "", errors: null };
    },
  },
});

export { birthSlice };
