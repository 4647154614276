import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addTagState: { tag: null, isloading: "", errors: null },
  updateTagState: { tag: null, isloading: "", errors: null },
  fetchTagsState: { tags: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const tagSlice = createSlice({
  name: "tag",
  initialState: initialState,
  reducers: {
    addTag: (state) => {
      state.addTagState.isloading = "processing";
      state.addTagState.errors = null;
    },
    addTagSuccess: (state, action) => {
      state.addTagState.isloading = "success";
      state.addTagState.tag = action.payload;
    },
    addTagFailed: (state, action) => {
      state.addTagState.isloading = "failed";
      state.addTagState.errors = action.payload;
    },
    updateTag: (state) => {
      state.updateTagState.isloading = "processing";
      state.updateTagState.errors = null;
    },
    updateTagSuccess: (state, action) => {
      state.updateTagState.isloading = "success";
      state.updateTagState.tag = action.payload;
    },
    updateTagFailed: (state, action) => {
      state.updateTagState.isloading = "failed";
      state.updateTagState.errors = action.payload;
    },
    fetchTags: (state) => {
      state.fetchTagsState.isloading = "processing";
      state.fetchTagsState.errors = null;
    },
    fetchTagsSuccess: (state, action) => {
      console.log("fetchTagsSuccess action is ", action);
      state.fetchTagsState.isloading = "success";
      state.fetchTagsState.tags = action.payload;
    },
    fetchTagsFailed: (state, action) => {
      state.fetchTagsState.isloading = "failed";
      state.fetchTagsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddTag(state) {
      state.addTagState = {
        tag: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateTag(state) {
      state.updateTagState = {
        tag: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { tagSlice };
