import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addInsurance as addInsuranceApi,
  updateInsurance as updateInsuranceApi,
  fetchInsurances as fetchInsurancesApi,
} from "../apis/insuranceApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { insuranceSlice } from "../slices/insuranceSlice";

const goatActions = insuranceSlice.actions;

export function* addInsurance(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addInsuranceApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addInsuranceSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Insurance",
      "Goat Insurance Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add insurance";
    yield put(goatActions.addInsuranceFailed(message));
  }
}

export function* updateInsurance(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateInsuranceApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateInsuranceSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Insurance",
      "Goat Insurance Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add insurance";
    yield put(goatActions.updateInsuranceFailed(message));
  }
}

export function* fetchInsurances(actions) {
  try {
    const response = yield call(fetchInsurancesApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchInsurancesSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Insurances",
      "Failed to fetch goat insurances!",
    );
    yield put(goatActions.fetchInsurancesFailed());
  }
}

export function* watchAddInsurance() {
  yield takeLeading(goatActions.addInsurance.type, addInsurance);
}

export function* watchUpdateInsurance() {
  yield takeLeading(goatActions.updateInsurance.type, updateInsurance);
}

export function* watchFetchInsurances() {
  yield takeLatest(goatActions.fetchInsurances.type, fetchInsurances);
}
