import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addSaleState: { sale: null, isloading: "", errors: null },
  updateSaleState: { sale: null, isloading: "", errors: null },
  fetchSalesState: { sales: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const saleSlice = createSlice({
  name: "sale",
  initialState: initialState,
  reducers: {
    addSale: (state) => {
      state.addSaleState.isloading = "processing";
      state.addSaleState.errors = null;
    },
    addSaleSuccess: (state, action) => {
      state.addSaleState.isloading = "success";
      state.addSaleState.sale = action.payload;
    },
    addSaleFailed: (state, action) => {
      state.addSaleState.isloading = "failed";
      state.addSaleState.errors = action.payload;
    },
    updateSale: (state) => {
      state.updateSaleState.isloading = "processing";
      state.updateSaleState.errors = null;
    },
    updateSaleSuccess: (state, action) => {
      state.updateSaleState.isloading = "success";
      state.updateSaleState.sale = action.payload;
    },
    updateSaleFailed: (state, action) => {
      state.updateSaleState.isloading = "failed";
      state.updateSaleState.errors = action.payload;
    },
    fetchSales: (state) => {
      state.fetchSalesState.isloading = "processing";
      state.fetchSalesState.errors = null;
    },
    fetchSalesSuccess: (state, action) => {
      console.log("fetchSalesSuccess action is ", action);
      state.fetchSalesState.isloading = "success";
      state.fetchSalesState.sales = action.payload;
    },
    fetchSalesFailed: (state, action) => {
      state.fetchSalesState.isloading = "failed";
      state.fetchSalesState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddSale(state) {
      state.addSaleState = {
        sale: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateSale(state) {
      state.updateSaleState = {
        sale: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { saleSlice };
