import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addBreedState: { breed: null, isloading: "", errors: null },
  updateBreedState: { breed: null, isloading: "", errors: null },
  fetchBreedsState: { breeds: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const breedSlice = createSlice({
  name: "breed",
  initialState: initialState,
  reducers: {
    addBreed: (state) => {
      state.addBreedState.isloading = "processing";
      state.addBreedState.errors = null;
    },
    addBreedSuccess: (state, action) => {
      state.addBreedState.isloading = "success";
      state.addBreedState.breed = action.payload;
    },
    addBreedFailed: (state, action) => {
      state.addBreedState.isloading = "failed";
      state.addBreedState.errors = action.payload;
    },
    updateBreed: (state) => {
      state.updateBreedState.isloading = "processing";
      state.updateBreedState.errors = null;
    },
    updateBreedSuccess: (state, action) => {
      state.updateBreedState.isloading = "success";
      state.updateBreedState.breed = action.payload;
    },
    updateBreedFailed: (state, action) => {
      state.updateBreedState.isloading = "failed";
      state.updateBreedState.errors = action.payload;
    },
    fetchBreeds: (state) => {
      state.fetchBreedsState.isloading = "processing";
      state.fetchBreedsState.errors = null;
    },
    fetchBreedsSuccess: (state, action) => {
      state.fetchBreedsState.isloading = "success";
      const { results, count } = action.payload;
      state.fetchBreedsState.breeds = results;
      state.fetchBreedsState.totalCount = count;
    },
    fetchBreedsFailed: (state, action) => {
      state.fetchBreedsState.isloading = "failed";
      state.fetchBreedsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddBreed(state) {
      state.addBreedState = { breed: null, isloading: "", errors: null };
    },
    resetUpdateBreed(state) {
      state.updateBreedState = { breed: null, isloading: "", errors: null };
    },
  },
});

export { breedSlice };
