import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addTemperatureCheck as addTemperatureCheckApi,
  updateTemperatureCheck as updateTemperatureCheckApi,
  fetchTemperatureChecks as fetchTemperatureChecksApi,
} from "../apis/temperaturecheckApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { temperaturecheckSlice } from "../slices/temperaturecheckSlice";

const goatActions = temperaturecheckSlice.actions;

export function* addTemperatureCheck(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addTemperatureCheckApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addTemperatureCheckSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Temperature Check",
      "Goat Temperature Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add temperature check";
    yield put(goatActions.addTemperatureCheckFailed(message));
  }
}

export function* updateTemperatureCheck(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateTemperatureCheckApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateTemperatureCheckSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Temperature Check",
      "Goat Temperature Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update temperature check";
    yield put(goatActions.updateTemperatureCheckFailed(message));
  }
}

export function* fetchTemperatureChecks(actions) {
  try {
    const response = yield call(fetchTemperatureChecksApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchTemperatureChecksSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Temperature Check",
      "Failed to perform temperature check!",
    );
    yield put(goatActions.fetchTemperatureChecksFailed());
  }
}

export function* watchAddTemperatureCheck() {
  yield takeLeading(goatActions.addTemperatureCheck.type, addTemperatureCheck);
}

export function* watchUpdateTemperatureCheck() {
  yield takeLeading(
    goatActions.updateTemperatureCheck.type,
    updateTemperatureCheck,
  );
}

export function* watchFetchTemperatureChecks() {
  yield takeLatest(
    goatActions.fetchTemperatureChecks.type,
    fetchTemperatureChecks,
  );
}
