import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addGoatBirth as addGoatBirthApi,
  updateGoatBirth as updateGoatBirthApi,
  fetchGoatBirths as fetchGoatBirthsApi,
} from "../apis/birthApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { birthSlice } from "../slices/birthSlice";

const birthActions = birthSlice.actions;

export function* addGoatBirth(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addGoatBirthApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(birthActions.addGoatBirthSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Births",
      "Goat Births Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to register birth";
    yield put(birthActions.addGoatBirthFailed(message));
  }
}

export function* updateGoatBirth(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateGoatBirthApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(birthActions.updateGoatBirthSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Births",
      "Goat Births Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to register birth";
    yield put(birthActions.updateGoatBirthFailed(message));
  }
}

export function* fetchGoatBirths(actions) {
  try {
    const response = yield call(fetchGoatBirthsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      birthActions.fetchGoatBirthsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Goat Births",
      "Failed to fetch goat births!",
    );
    yield put(birthActions.fetchGoatBirthsFailed());
  }
}

export function* watchAddGoatBirth() {
  yield takeLeading(birthActions.addGoatBirth.type, addGoatBirth);
}

export function* watchUpdateGoatBirth() {
  yield takeLeading(birthActions.updateGoatBirth.type, updateGoatBirth);
}

export function* watchFetchGoatBirths() {
  yield takeLatest(birthActions.fetchGoatBirths.type, fetchGoatBirths);
}
