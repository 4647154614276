import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addTemperatureCheckState: {
    temperaturecheck: null,
    isloading: "",
    errors: null,
  },
  updateTemperatureCheckState: {
    temperaturecheck: null,
    isloading: "",
    errors: null,
  },
  fetchTemperatureChecksState: {
    temperaturechecks: [],
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const temperaturecheckSlice = createSlice({
  name: "temperaturecheck",
  initialState: initialState,
  reducers: {
    addTemperatureCheck: (state) => {
      state.addTemperatureCheckState.isloading = "processing";
      state.addTemperatureCheckState.errors = null;
    },
    addTemperatureCheckSuccess: (state, action) => {
      state.addTemperatureCheckState.isloading = "success";
      state.addTemperatureCheckState.temperaturecheck = action.payload;
    },
    addTemperatureCheckFailed: (state, action) => {
      state.addTemperatureCheckState.isloading = "failed";
      state.addTemperatureCheckState.errors = action.payload;
    },
    updateTemperatureCheck: (state) => {
      state.updateTemperatureCheckState.isloading = "processing";
      state.updateTemperatureCheckState.errors = null;
    },
    updateTemperatureCheckSuccess: (state, action) => {
      state.updateTemperatureCheckState.isloading = "success";
      state.updateTemperatureCheckState.temperaturecheck = action.payload;
    },
    updateTemperatureCheckFailed: (state, action) => {
      state.updateTemperatureCheckState.isloading = "failed";
      state.updateTemperatureCheckState.errors = action.payload;
    },
    fetchTemperatureChecks: (state) => {
      state.fetchTemperatureChecksState.isloading = "processing";
      state.fetchTemperatureChecksState.errors = null;
    },
    fetchTemperatureChecksSuccess: (state, action) => {
      console.log("fetchTemperatureChecksSuccess action is ", action);
      state.fetchTemperatureChecksState.isloading = "success";
      state.fetchTemperatureChecksState.temperaturechecks = action.payload;
    },
    fetchTemperatureChecksFailed: (state, action) => {
      state.fetchTemperatureChecksState.isloading = "failed";
      state.fetchTemperatureChecksState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddTemperatureCheck(state) {
      state.addTemperatureCheckState = {
        temperatureCheck: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateTemperatureCheck(state) {
      state.updateTemperatureCheckState = {
        temperatureCheck: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { temperaturecheckSlice };
