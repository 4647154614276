import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGoatState: { goat: null, isloading: "", errors: null },
  updateGoatState: { goat: null, isloading: "", errors: null },
  fetchGoatsState: { goats: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const goatSlice = createSlice({
  name: "goat",
  initialState: initialState,
  reducers: {
    addGoat: (state) => {
      state.addGoatState.isloading = "processing";
      state.addGoatState.errors = null;
    },
    addGoatSuccess: (state, action) => {
      state.addGoatState.isloading = "success";
      state.addGoatState.goat = action.payload;
    },
    addGoatFailed: (state, action) => {
      state.addGoatState.isloading = "failed";
      state.addGoatState.errors = action.payload;
    },
    updateGoat: (state) => {
      state.updateGoatState.isloading = "processing";
      state.updateGoatState.errors = null;
    },
    updateGoatSuccess: (state, action) => {
      state.updateGoatState.isloading = "success";
      state.updateGoatState.goat = action.payload;
    },
    updateGoatFailed: (state, action) => {
      state.updateGoatState.isloading = "failed";
      state.updateGoatState.errors = action.payload;
    },
    fetchGoats: (state) => {
      state.fetchGoatsState.isloading = "processing";
      state.fetchGoatsState.errors = null;
    },
    fetchGoatsSuccess: (state, action) => {
      console.log("fetchGoatsSuccess action is ", action);
      state.fetchGoatsState.isloading = "success";
      state.fetchGoatsState.goats = action.payload;
    },
    fetchGoatsFailed: (state, action) => {
      state.fetchGoatsState.isloading = "failed";
      state.fetchGoatsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoat(state) {
      state.addGoatState = { goat: null, isloading: "", errors: null };
    },
    resetUpdateGoat(state) {
      state.updateGoatState = { goat: null, isloading: "", errors: null };
    },
  },
});

export { goatSlice };
