import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addAccounts as addAccountsApi,
  updateAccounts as updateAccountsApi,
  fetchAccounts as fetchAccountsApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { accountsSlice } from "./slice";

const accountsActions = accountsSlice.actions;

export function* addAccounts(actions) {
  try {
    const {
      unit_price,
      farm,
      date,
      detail,
      revenue_type,
      goats,
    } = actions.payload;

    const response = yield call(addAccountsApi, {
      unit_price,
      farm,
      date,
      detail,
      revenue_type,
      goats,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(accountsActions.addAccountsSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Add Accounts",
      "Accounts Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add accounts";
    yield put(accountsActions.addAccountsFailed(message));
  }
}

export function* updateAccounts(actions) {
  try {
    const { id, unit_price, farm, date, detail, goats } = actions.payload;

    const response = yield call(updateAccountsApi, id, {
      unit_price,
      farm,
      date,
      detail,
      goats,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(accountsActions.updateAccountsSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Accounts",
      "Accounts Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add accounts";
    yield put(accountsActions.updateAccountsFailed(message));
  }
}

export function* fetchAccounts(actions) {
  try {
    const response = yield call(fetchAccountsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    console.log("response is ", response);
    yield put(
      accountsActions.fetchAccountsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (e) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Accounts",
      "Failed to fetch accounts!",
    );
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to add accounts";
    yield put(accountsActions.fetchAccountsFailed(message));
  }
}

export function* watchAddAccounts() {
  yield takeLeading(accountsActions.addAccounts.type, addAccounts);
}

export function* watchUpdateAccounts() {
  yield takeLeading(accountsActions.updateAccounts.type, updateAccounts);
}

export function* watchFetchAccounts() {
  yield takeLatest(accountsActions.fetchAccounts.type, fetchAccounts);
}
