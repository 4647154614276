import { notification, message } from "antd";

export const messageConfig = () => {
	return message.config({
		duration: 2,
		top: 96,
	});
};

export const notificationConfig = () => {
	return notification.config({
		duration: 3,
		placement: "bottomRight",
	});
};
