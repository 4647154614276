import {
	axiosUnAuthenticatedInstance,
	axiosAuthenticatedInstance,
} from "./axiosDefaults";

export const apiUnAuthenticatedRequest = (method, url, data = {}) => {
	const response = axiosUnAuthenticatedInstance()({
		method,
		url,
		data,
	});

	return response;
};

export const apiAuthenticatedRequest = (method, url, data = {}) => {
	const response = axiosAuthenticatedInstance()({
		method,
		url,
		data,
		withCredentials: false,
	});

	return response;
};
