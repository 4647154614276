import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { ACCOUNTS_ENDPOINT } from "../../services/api/urls";

export const addAccounts = (data) => {
	return apiAuthenticatedRequest("POST", ACCOUNTS_ENDPOINT, data);
};

export const updateAccounts = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${ACCOUNTS_ENDPOINT}${id}/`, data);
};

export const fetchAccounts = (query) => {
	console.log("query is ", query);
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let revenue_type = query && query.revenue_type;

	return apiAuthenticatedRequest(
		"GET",
		`${ACCOUNTS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${revenue_type ? `&revenue_type=${revenue_type}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
