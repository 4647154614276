import { call, put, takeLatest } from "redux-saga/effects";
import { fetchTreatmentReports as fetchTreatmentReportsApi } from "../apis/treatmentApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { treatmentSlice } from "../slices/treatmentSlice";

const treatmentActions = treatmentSlice.actions;

export function* fetchTreatmentReports(actions) {
	try {
		const response = yield call(fetchTreatmentReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			treatmentActions.fetchTreatmentReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch treatment reports",
			"Failed to fetch treatment reports!",
		);
		yield put(treatmentActions.fetchTreatmentReportsFailed());
	}
}

export function* watchFetchTreatmentReports() {
	yield takeLatest(
		treatmentActions.fetchTreatmentReports.type,
		fetchTreatmentReports,
	);
}
