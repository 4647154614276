import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchFarmersState: {
    farmers: [],
    totalCount: 0,
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const farmerSlice = createSlice({
  name: "farmer",
  initialState: initialState,
  reducers: {
    fetchFarmers: (state) => {
      state.fetchFarmersState.isloading = "processing";
      state.fetchFarmersState.errors = null;
    },
    fetchFarmersSuccess: (state, action) => {
      state.fetchFarmersState.isloading = "success";
      const { count, results } = action.payload;
      state.fetchFarmersState.farmers = results;
      state.fetchFarmersState.totalCount = count;
    },
    fetchFarmersFailed: (state, action) => {
      state.fetchFarmersState.isloading = "failed";
      state.fetchFarmersState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { farmerSlice };
