import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addGoatMovement as addGoatMovementApi,
  updateGoatMovement as updateGoatMovementApi,
  fetchGoatMovements as fetchGoatMovementsApi,
} from "../apis/movementApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { movementSlice } from "../slices/movementSlice";

const goatActions = movementSlice.actions;

export function* addGoatMovement(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addGoatMovementApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addGoatMovementSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Movement",
      "Goat Movement Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add movement";
    yield put(goatActions.addGoatMovementFailed(message));
  }
}

export function* updateGoatMovement(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateGoatMovementApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateGoatMovementSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Movement",
      "Goat Movement Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add movement";
    yield put(goatActions.updateGoatMovementFailed(message));
  }
}

export function* fetchGoatMovements(actions) {
  try {
    const response = yield call(fetchGoatMovementsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchGoatMovementsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Movements",
      "Failed to fetch goat movements!",
    );
    yield put(goatActions.fetchGoatMovementsFailed());
  }
}

export function* watchAddGoatMovement() {
  yield takeLeading(goatActions.addGoatMovement.type, addGoatMovement);
}

export function* watchUpdateGoatMovement() {
  yield takeLeading(goatActions.updateGoatMovement.type, updateGoatMovement);
}

export function* watchFetchGoatMovements() {
  yield takeLatest(goatActions.fetchGoatMovements.type, fetchGoatMovements);
}
