import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { BREED_ENDPOINT } from "../../services/api/urls";

export const addBreed = (data) => {
	return apiAuthenticatedRequest("POST", BREED_ENDPOINT, data);
};

export const updateBreed = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${BREED_ENDPOINT}${id}/`, data);
};

export const fetchBreeds = (query) => {
	let search = query && query.search;
	let page = query && query.page;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;

	return apiAuthenticatedRequest(
		"GET",
		`${BREED_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${page ? `&page=${page}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
