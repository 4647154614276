import React, { Component, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import SideBar from "../components/SideBar";
import Header from "../components/Header";
import Container from "../components/Container";
import { Layout } from "antd";

const { Footer } = Layout;

//function to retry lazy imports up to x times if it fails, before it errors out
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

// code splitting...
const DashboardPromise = import("../containers/Dashboard/components");
const Dashboard = lazy(() => retry(() => DashboardPromise));

const ListFarmsPromise = import("../containers/MyFarm/components/ListFarms");
const ListFarms = lazy(() => retry(() => ListFarmsPromise));

const AddFarmPromise = import("../containers/MyFarm/components/AddFarm");
const AddFarm = lazy(() => retry(() => AddFarmPromise));

const ListPaddocksPromise = import(
  "../containers/Paddock/components/ListPaddocks"
);
const ListPaddocks = lazy(() => retry(() => ListPaddocksPromise));

const AddPaddockPromise = import("../containers/Paddock/components/AddPaddock");
const AddPaddock = lazy(() => retry(() => AddPaddockPromise));

const ListProductsPromise = import(
  "../containers/Product/components/ListProducts"
);
const ListProducts = lazy(() => retry(() => ListProductsPromise));

const AddProductPromise = import("../containers/Product/components/AddProduct");
const AddProduct = lazy(() => retry(() => AddProductPromise));

const ListProductCategoriesPromise = import(
  "../containers/ProductCategory/components/ListProductCategories"
);
const ListProductCategories = lazy(() =>
  retry(() => ListProductCategoriesPromise),
);

const AddProductCategoryPromise = import(
  "../containers/ProductCategory/components/AddProductCategory"
);
const AddProductCategory = lazy(() => retry(() => AddProductCategoryPromise));

const ListGoatsPromise = import("../containers/Goat/components/ListGoats");
const ListGoats = lazy(() => retry(() => ListGoatsPromise));

const AddGoatPromise = import("../containers/Goat/components/AddGoat");
const AddGoat = lazy(() => retry(() => AddGoatPromise));

const ListBreedsPromise = import("../containers/Breed/components/ListBreeds");
const ListBreeds = lazy(() => retry(() => ListBreedsPromise));

const AddBreedPromise = import("../containers/Breed/components/AddBreed");
const AddBreed = lazy(() => retry(() => AddBreedPromise));

const ListRevenuesPromise = import(
  "../containers/Accounts/components/Revenues/ListRevenues"
);
const ListRevenues = lazy(() => retry(() => ListRevenuesPromise));

const ListExpensesPromise = import(
  "../containers/Accounts/components/Expenses/ListExpenses"
);
const ListExpenses = lazy(() => retry(() => ListExpensesPromise));

const AddRevenuesPromise = import(
  "../containers/Accounts/components/Revenues/AddRevenue"
);
const AddRevenues = lazy(() => retry(() => AddRevenuesPromise));

const AddExpensesPromise = import(
  "../containers/Accounts/components/Expenses/AddExpense"
);
const AddExpenses = lazy(() => retry(() => AddExpensesPromise));

const ListAddressBooksPromise = import(
  "../containers/AddressBook/components/ListAddressBooks"
);
const ListAddressBooks = lazy(() => retry(() => ListAddressBooksPromise));

const AddAddressBookPromise = import(
  "../containers/AddressBook/components/AddAddressBook"
);
const AddAddressBook = lazy(() => retry(() => AddAddressBookPromise));

const EventsPromise = import("../containers/Events/components");
const Events = lazy(() => retry(() => EventsPromise));

const ReportsPromise = import("../containers/Reports/components");
const Reports = lazy(() => retry(() => ReportsPromise));

export default class Routes extends Component {
  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    const { match } = this.props;
    console.log("URL match is ", match);
    const routes = [
      {
        path: "/",
        exact: true,
        component: Dashboard,
      },
      {
        path: "/myfarm",
        exact: true,
        component: ListFarms,
      },
      {
        path: "/add/farm",
        exact: true,
        component: AddFarm,
      },
      {
        path: "/paddocks",
        exact: true,
        component: ListPaddocks,
      },
      {
        path: "/add-paddock",
        exact: true,
        component: AddPaddock,
      },
      {
        path: "/breeds",
        exact: true,
        component: ListBreeds,
      },
      {
        path: "/add-breed",
        exact: true,
        component: AddBreed,
      },
      {
        path: "/goats",
        exact: true,
        component: ListGoats,
      },
      {
        path: "/add-goat",
        exact: true,
        component: AddGoat,
      },
      {
        path: "/products",
        exact: true,
        component: ListProducts,
      },
      {
        path: "/add-product",
        exact: true,
        component: AddProduct,
      },
      {
        path: "/product-categories",
        exact: true,
        component: ListProductCategories,
      },
      {
        path: "/add-product-category",
        exact: true,
        component: AddProductCategory,
      },
      {
        path: "/address-books",
        exact: true,
        component: ListAddressBooks,
      },
      {
        path: "/add-address-book",
        exact: true,
        component: AddAddressBook,
      },
      {
        path: "/revenues",
        exact: true,
        component: ListRevenues,
      },
      {
        path: "/add-revenue",
        exact: true,
        component: AddRevenues,
      },
      {
        path: "/expenses",
        exact: true,
        component: ListExpenses,
      },
      {
        path: "/add-expense",
        exact: true,
        component: AddExpenses,
      },
      {
        path: "/events",
        exact: true,
        component: Events,
      },
      {
        path: "/reports",
        exact: true,
        component: Reports,
      },
      {
        path: "*",
        exact: true,
        render: () => <Redirect to={{ pathname: "/app" }} />,
      },
    ];

    return (
      <Layout>
        <SideBar collapsed={this.state.collapsed} />
        <Layout className="site-layout">
          <Header collapsed={this.state.collapsed} toggle={this.toggle} />
          <Container>
            <Switch>
              {routes.map((route, i) => {
                return (
                  <Route
                    key={i}
                    exact={route.exact}
                    path={`${match.url}${route.path}`}
                    render={route && route.render}
                    component={route.component}
                  />
                );
              })}
            </Switch>
          </Container>
          <Footer style={{ textAlign: "center" }}>
            Property Management ©2021 Created by Hive Technologies Ltd
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
