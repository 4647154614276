import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addTag as addTagApi,
  updateTag as updateTagApi,
  fetchTags as fetchTagsApi,
} from "../apis/tagApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { tagSlice } from "../slices/tagSlice";

const goatActions = tagSlice.actions;

export function* addTag(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addTagApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addTagSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Tag",
      "Goat Tag Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) || "Failed to add tag";
    yield put(goatActions.addTagFailed(message));
  }
}

export function* updateTag(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateTagApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateTagSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Tag",
      "Goat Tag Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) || "Failed to add tag";
    yield put(goatActions.updateTagFailed(message));
  }
}

export function* fetchTags(actions) {
  try {
    const response = yield call(fetchTagsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchTagsSuccess(resp && resp.results, resp && resp.count),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Tags",
      "Failed to fetch goat tags!",
    );
    yield put(goatActions.fetchTagsFailed());
  }
}

export function* watchAddTag() {
  yield takeLeading(goatActions.addTag.type, addTag);
}

export function* watchUpdateTag() {
  yield takeLeading(goatActions.updateTag.type, updateTag);
}

export function* watchFetchTags() {
  yield takeLatest(goatActions.fetchTags.type, fetchTags);
}
