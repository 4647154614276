import { call, put, takeLatest } from "redux-saga/effects";
import { fetchWeaningReports as fetchWeaningReportsApi } from "../apis/weaningApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { weaningSlice } from "../slices/weaningSlice";

const weaningActions = weaningSlice.actions;

export function* fetchWeaningReports(actions) {
	try {
		const response = yield call(fetchWeaningReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			weaningActions.fetchWeaningReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Weaning Reports",
			"Failed to fetch weaning reports!",
		);
		yield put(weaningActions.fetchWeaningReportsFailed());
	}
}

export function* watchFetchWeaningReports() {
	yield takeLatest(
		weaningActions.fetchWeaningReports.type,
		fetchWeaningReports,
	);
}
