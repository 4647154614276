import { all } from "redux-saga/effects";
import { watchLogin, watchLogout } from "./containers/Auth/sagas";
import {
  watchAddFarm,
  watchUpdateFarm,
  watchFetchFarms,
} from "./containers/MyFarm/sagas";
import {
  watchAddPaddock,
  watchUpdatePaddock,
  watchFetchPaddocks,
} from "./containers/Paddock/sagas";
import {
  watchAddProduct,
  watchUpdateProduct,
  watchFetchProducts,
} from "./containers/Product/sagas";
import {
  watchAddProductCategory,
  watchUpdateProductCategory,
  watchFetchProductCategories,
} from "./containers/ProductCategory/sagas";
import {
  watchAddBreed,
  watchUpdateBreed,
  watchFetchBreeds,
} from "./containers/Breed/sagas";
import {
  watchAddGoat,
  watchUpdateGoat,
  watchFetchGoats,
} from "./containers/Goat/sagas";
import {
  watchAddAddressBook,
  watchUpdateAddressBook,
  watchFetchAddressBooks,
} from "./containers/AddressBook/sagas";
import {
  watchAddAccounts,
  watchUpdateAccounts,
  watchFetchAccounts,
} from "./containers/Accounts/sagas";
import {
  watchAddFeedOut,
  watchUpdateFeedOut,
  watchFetchFeedOuts,
} from "./containers/Events/sagas/feedoutSagas";
import {
  watchAddGoatCastration,
  watchUpdateGoatCastration,
  watchFetchGoatCastrations,
} from "./containers/Events/sagas/castrationSagas";
import {
  watchAddInsurance,
  watchUpdateInsurance,
  watchFetchInsurances,
} from "./containers/Events/sagas/insuranceSagas";
import {
  watchAddGoatMovement,
  watchUpdateGoatMovement,
  watchFetchGoatMovements,
} from "./containers/Events/sagas/movementSagas";
import {
  watchAddProcessing,
  watchUpdateProcessing,
  watchFetchProcessings,
} from "./containers/Events/sagas/processingSagas";
import {
  watchAddPurchase,
  watchUpdatePurchase,
  watchFetchPurchases,
} from "./containers/Events/sagas/purchaseSagas";
import {
  watchAddSale,
  watchUpdateSale,
  watchFetchSales,
} from "./containers/Events/sagas/saleSagas";
import {
  watchAddSpray,
  watchUpdateSpray,
  watchFetchSprays,
} from "./containers/Events/sagas/spraySagas";
import {
  watchAddTag,
  watchUpdateTag,
  watchFetchTags,
} from "./containers/Events/sagas/tagSagas";
import {
  watchAddTreatment,
  watchUpdateTreatment,
  watchFetchTreatments,
} from "./containers/Events/sagas/treatmentSagas";
import {
  watchAddWeaning,
  watchUpdateWeaning,
  watchFetchWeanings,
} from "./containers/Events/sagas/weaningSagas";
import {
  watchAddNewEvent,
  watchUpdateNewEvent,
  watchFetchNewEvents,
} from "./containers/Events/sagas/neweventSagas";
import {
  watchAddTemperatureCheck,
  watchUpdateTemperatureCheck,
  watchFetchTemperatureChecks,
} from "./containers/Events/sagas/temperaturecheckSagas";
import {
  watchAddGoatDeath,
  watchUpdateGoatDeath,
  watchFetchGoatDeaths,
} from "./containers/Events/sagas/deathSagas";
import {
  watchAddGoatBirth,
  watchUpdateGoatBirth,
  watchFetchGoatBirths,
} from "./containers/Events/sagas/birthSagas";
import { watchFetchGoatReports } from "./containers/Reports/sagas/goatSagas";
import { watchFetchIncomeReports } from "./containers/Reports/sagas/incomeSagas";
import { watchFetchGoatBirthReports } from "./containers/Reports/sagas/birthSagas";
import { watchFetchGoatDeathReports } from "./containers/Reports/sagas/deathSagas";
import { watchFetchPurchaseReports } from "./containers/Reports/sagas/purchaseSagas";
import { watchFetchFeedOutReports } from "./containers/Reports/sagas/feedoutSagas";
import { watchFetchTreatmentReports } from "./containers/Reports/sagas/treatmentSagas";
import { watchFetchExpenseReports } from "./containers/Reports/sagas/expenseSagas";
import { watchFetchWeaningReports } from "./containers/Reports/sagas/weaningSagas";
import { watchFetchSaleReports } from "./containers/Reports/sagas/saleSagas";
import { watchFetchStatistics } from "./containers/Dashboard/sagas/statisticsSaga";
import { watchFetchFarmers } from "./containers/Farmers/sagas";

export default function* rootSaga() {
  yield all([
    watchLogin(),
    watchLogout(),
    watchAddFarm(),
    watchUpdateFarm(),
    watchFetchFarms(),
    watchAddPaddock(),
    watchUpdatePaddock(),
    watchFetchPaddocks(),
    watchAddProduct(),
    watchUpdateProduct(),
    watchFetchProducts(),
    watchAddProductCategory(),
    watchUpdateProductCategory(),
    watchFetchProductCategories(),
    watchAddBreed(),
    watchUpdateBreed(),
    watchFetchBreeds(),
    watchAddGoat(),
    watchUpdateGoat(),
    watchFetchGoats(),
    watchAddAddressBook(),
    watchUpdateAddressBook(),
    watchFetchAddressBooks(),
    watchAddAccounts(),
    watchUpdateAccounts(),
    watchFetchAccounts(),
    watchAddFeedOut(),
    watchUpdateFeedOut(),
    watchFetchFeedOuts(),
    watchAddGoatBirth(),
    watchUpdateGoatBirth(),
    watchFetchGoatBirths(),
    watchFetchGoatReports(),
    watchFetchIncomeReports(),
    watchFetchGoatBirthReports(),
    watchFetchGoatDeathReports(),
    watchFetchPurchaseReports(),
    watchFetchFeedOutReports(),
    watchFetchTreatmentReports(),
    watchFetchExpenseReports(),
    watchFetchWeaningReports(),
    watchFetchSaleReports(),
    watchAddGoatCastration(),
    watchUpdateGoatCastration(),
    watchFetchGoatCastrations(),
    watchAddInsurance(),
    watchUpdateInsurance(),
    watchFetchInsurances(),
    watchAddGoatMovement(),
    watchUpdateGoatMovement(),
    watchFetchGoatMovements(),
    watchAddProcessing(),
    watchUpdateProcessing(),
    watchFetchProcessings(),
    watchAddPurchase(),
    watchUpdatePurchase(),
    watchFetchPurchases(),
    watchAddSale(),
    watchUpdateSale(),
    watchFetchSales(),
    watchAddSpray(),
    watchUpdateSpray(),
    watchFetchSprays(),
    watchAddTreatment(),
    watchUpdateTreatment(),
    watchFetchTreatments(),
    watchAddWeaning(),
    watchUpdateWeaning(),
    watchFetchWeanings(),
    watchAddNewEvent(),
    watchUpdateNewEvent(),
    watchFetchNewEvents(),
    watchAddTemperatureCheck(),
    watchUpdateTemperatureCheck(),
    watchFetchTemperatureChecks(),
    watchAddGoatDeath(),
    watchUpdateGoatDeath(),
    watchFetchGoatDeaths(),
    watchAddGoatBirth(),
    watchFetchGoatBirths(),
    watchAddTag(),
    watchUpdateTag(),
    watchFetchTags(),
    watchFetchStatistics(),
    watchFetchFarmers(),
  ]);
}
