import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addAddressBook as addAddressBookApi,
  updateAddressBook as updateAddressBookApi,
  fetchAddressBooks as fetchAddressBooksApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { addressBookSlice } from "./slice";

const addressBookActions = addressBookSlice.actions;

export function* addAddressBook(actions) {
  try {
    const { name, phone, alt_phone, extra_info } = actions.payload;

    const response = yield call(addAddressBookApi, {
      name,
      phone,
      alt_phone,
      extra_info,
      user: getUser().id,
    });
    const resp = response && response.data;

    yield put(addressBookActions.addAddressBookSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Add Address book",
      "Address book Added Successful!",
    );
  } catch (e) {
    console.log("error message ", e && e.response && e.response.data);
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add address book";
    yield put(addressBookActions.addAddressBookFailed(message));
  }
}

export function* updateAddressBook(actions) {
  try {
    const { id, name, phone, alt_phone, extra_info } = actions.payload;

    const response = yield call(updateAddressBookApi, id, {
      name,
      phone,
      alt_phone,
      extra_info,
      user: getUser().id,
    });
    const resp = response && response.data;

    yield put(addressBookActions.updateAddressBookSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Address book",
      "Address book Updated Successful!",
    );
  } catch (e) {
    console.log("error message ", e && e.response && e.response.data);
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add address book";
    yield put(addressBookActions.updateAddressBookFailed(message));
  }
}

export function* fetchAddressBooks(actions) {
  try {
    const response = yield call(fetchAddressBooksApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    console.log("response is ", response);
    yield put(
      addressBookActions.fetchAddressBooksSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (e) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Address Books",
      "Failed to fetch Address books!",
    );
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to fetch address books";
    yield put(addressBookActions.fetchAddressBooksFailed(message));
  }
}

export function* watchAddAddressBook() {
  yield takeLeading(addressBookActions.addAddressBook.type, addAddressBook);
}

export function* watchUpdateAddressBook() {
  yield takeLeading(
    addressBookActions.updateAddressBook.type,
    updateAddressBook,
  );
}

export function* watchFetchAddressBooks() {
  yield takeLatest(
    addressBookActions.fetchAddressBooks.type,
    fetchAddressBooks,
  );
}
