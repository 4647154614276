import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addGoatDeath as addGoatDeathApi,
  updateGoatDeath as updateGoatDeathApi,
  fetchGoatDeaths as fetchGoatDeathsApi,
} from "../apis/deathApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { deathSlice } from "../slices/deathSlice";

const goatActions = deathSlice.actions;

export function* addGoatDeath(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addGoatDeathApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addGoatDeathSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Death",
      "Goat Death Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to register goat death";
    yield put(goatActions.addGoatDeathFailed(message));
  }
}

export function* updateGoatDeath(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateGoatDeathApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateGoatDeathSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Death",
      "Goat Death Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to register goat death";
    yield put(goatActions.updateGoatDeathFailed(message));
  }
}

export function* fetchGoatDeaths(actions) {
  try {
    const response = yield call(fetchGoatDeathsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchGoatDeathsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Deaths",
      "Failed to fetch goat deaths!",
    );
    yield put(goatActions.fetchGoatDeathsFailed());
  }
}

export function* watchAddGoatDeath() {
  yield takeLeading(goatActions.addGoatDeath.type, addGoatDeath);
}

export function* watchUpdateGoatDeath() {
  yield takeLeading(goatActions.updateGoatDeath.type, updateGoatDeath);
}

export function* watchFetchGoatDeaths() {
  yield takeLatest(goatActions.fetchGoatDeaths.type, fetchGoatDeaths);
}
