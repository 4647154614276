import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addSpray as addSprayApi,
  updateSpray as updateSprayApi,
  fetchSprays as fetchSpraysApi,
} from "../apis/sprayApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { spraySlice } from "../slices/spraySlice";

const goatActions = spraySlice.actions;

export function* addSpray(actions) {
  try {
    const response = yield call(addSprayApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addSpraySuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Spraying",
      "Goat Spraying Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add spraying";
    yield put(goatActions.addSprayFailed(message));
  }
}

export function* updateSpray(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateSprayApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateSpraySuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Spraying",
      "Goat Spraying Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update spraying";
    yield put(goatActions.updateSprayFailed(message));
  }
}

export function* fetchSprays(actions) {
  try {
    const response = yield call(fetchSpraysApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchSpraysSuccess(resp && resp.results, resp && resp.count),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Spraying",
      "Failed to fetch goat spraying!",
    );
    yield put(goatActions.fetchSpraysFailed());
  }
}

export function* watchAddSpray() {
  yield takeLeading(goatActions.addSpray.type, addSpray);
}

export function* watchUpdateSpray() {
  yield takeLeading(goatActions.updateSpray.type, updateSpray);
}

export function* watchFetchSprays() {
  yield takeLatest(goatActions.fetchSprays.type, fetchSprays);
}
