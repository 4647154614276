import axios from "axios";
import { getLocalState } from "../app-storage";
import { BASE_URL } from "./urls";

export const getToken = () => {
  let token = getLocalState("mefarm-farmer-access");
  return token && token.token;
};

export const getUser = () => {
  let obj = getLocalState("mefarm-farmer-access");
  return obj && obj.user;
};

// for endpoints that do not require jwt
export const axiosUnAuthenticatedInstance = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  return instance;
};

// for endpoints that require jwt
export const axiosAuthenticatedInstance = () => {
  var AUTH_TOKEN = getToken();
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + AUTH_TOKEN,
    },
  });

  return instance;
};

export const processErrors = (errorObj) => {
  // let errorsArray = ["Email must be long enough"]; //look at the example in Address book for proper display
  let errorsArray = [];
  for (const [key, value] of Object.entries(errorObj)) {
    console.log(`${key}: ${value}`);
    console.log(value);
    console.log("errorsArray ", errorsArray);
    errorsArray.push(`${value}`);
  }
  console.log("errorsArray after ", errorsArray);
  return errorsArray;
};
