import { call, put, takeLatest } from "redux-saga/effects";
import { fetchFeedOutReports as fetchFeedOutReportsApi } from "../apis/feedoutApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { feedoutSlice } from "../slices/feedoutSlice";

const feedOutActions = feedoutSlice.actions;

export function* fetchFeedOutReports(actions) {
	try {
		const response = yield call(fetchFeedOutReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			feedOutActions.fetchFeedOutReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Feedout Reports",
			"Failed to fetch goat feedout reports!",
		);
		yield put(feedOutActions.fetchFeedOutReportsFailed());
	}
}

export function* watchFetchFeedOutReports() {
	yield takeLatest(
		feedOutActions.fetchFeedOutReports.type,
		fetchFeedOutReports,
	);
}
