import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addPurchaseState: { purchase: null, isloading: "", errors: null },
  updatePurchaseState: { purchase: null, isloading: "", errors: null },
  fetchPurchasesState: { purchases: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const purchaseSlice = createSlice({
  name: "purchase",
  initialState: initialState,
  reducers: {
    addPurchase: (state) => {
      state.addPurchaseState.isloading = "processing";
      state.addPurchaseState.errors = null;
    },
    addPurchaseSuccess: (state, action) => {
      state.addPurchaseState.isloading = "success";
      state.addPurchaseState.purchase = action.payload;
    },
    addPurchaseFailed: (state, action) => {
      state.addPurchaseState.isloading = "failed";
      state.addPurchaseState.errors = action.payload;
    },
    updatePurchase: (state) => {
      state.updatePurchaseState.isloading = "processing";
      state.updatePurchaseState.errors = null;
    },
    updatePurchaseSuccess: (state, action) => {
      state.updatePurchaseState.isloading = "success";
      state.updatePurchaseState.purchase = action.payload;
    },
    updatePurchaseFailed: (state, action) => {
      state.updatePurchaseState.isloading = "failed";
      state.updatePurchaseState.errors = action.payload;
    },
    fetchPurchases: (state) => {
      state.fetchPurchasesState.isloading = "processing";
      state.fetchPurchasesState.errors = null;
    },
    fetchPurchasesSuccess: (state, action) => {
      console.log("fetchPurchasesSuccess action is ", action);
      state.fetchPurchasesState.isloading = "success";
      state.fetchPurchasesState.purchases = action.payload;
    },
    fetchPurchasesFailed: (state, action) => {
      state.fetchPurchasesState.isloading = "failed";
      state.fetchPurchasesState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddPurchase(state) {
      state.addPurchaseState = {
        purchase: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdatePurchase(state) {
      state.updatePurchaseState = {
        purchase: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { purchaseSlice };
