import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchGoatBirthReportsState: { birthReports: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const birthSlice = createSlice({
  name: "birthReports",
  initialState: initialState,
  reducers: {
    fetchGoatBirthReports: (state) => {
      state.fetchGoatBirthReportsState.isloading = "processing";
      state.fetchGoatBirthReportsState.errors = null;
    },
    fetchGoatBirthReportsSuccess: (state, action) => {
      console.log("fetchGoatBirthReportsSuccess action is ", action);
      state.fetchGoatBirthReportsState.isloading = "success";
      state.fetchGoatBirthReportsState.birthReports = action.payload;
    },
    fetchGoatBirthReportsFailed: (state, action) => {
      state.fetchGoatBirthReportsState.isloading = "failed";
      state.fetchGoatBirthReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { birthSlice };
