import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchPurchaseReportsState: {
    purchaseReports: [],
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const purchaseSlice = createSlice({
  name: "purchaseReports",
  initialState: initialState,
  reducers: {
    fetchPurchaseReports: (state) => {
      state.fetchPurchaseReportsState.isloading = "processing";
      state.fetchPurchaseReportsState.errors = null;
    },
    fetchPurchaseReportsSuccess: (state, action) => {
      console.log("fetchPurchaseReportsSuccess action is ", action);
      state.fetchPurchaseReportsState.isloading = "success";
      state.fetchPurchaseReportsState.purchaseReports = action.payload;
    },
    fetchPurchaseReportsFailed: (state, action) => {
      state.fetchPurchaseReportsState.isloading = "failed";
      state.fetchPurchaseReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { purchaseSlice };
