/* Base Urls */
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

/* Auth endpoints */
export const USERS_ENDPOINT = "/auth/";

export const FARMS_ENDPOINT = "/hegoat-farms/";

export const PADDOCKS_ENDPOINT = "/hegoat-paddocks/";

/* Products */
export const PRODUCTS_ENDPOINT = "/hegoat-product/";
export const PRODUCT_CATEGORY_ENDPOINT = "/hegoat-product-category/";

/* Goat */
export const GOATS_ENDPOINT = "/hegoat-goats/";

/* Breed */
export const BREED_ENDPOINT = "/hegoat-breed/";

/* Address book */
export const ADDRESS_BOOK_ENDPOINT = "/hegoat-addressbook/";

/* Accounts and Revenue */
export const ACCOUNTS_ENDPOINT = "/hegoat-revenue/";

/* Events */
export const FEED_OUT_ENDPOINT = "/hegoat-farmevents-feedout/";
export const GOAT_BIRTHS_ENDPOINT = "/hegoat-farmevents-birth/";
export const GOAT_DEATHS_ENDPOINT = "/hegoat-farmevents-death/";
export const GOAT_CASTRATION_ENDPOINT = "/hegoat-farmevents-castration/";
export const GOAT_MOVEMENT_ENDPOINT = "/hegoat-farmevents-movement/";
export const NEW_EVENT_ENDPOINT = "/hegoat-farmevents-newevent/";
export const PROCESSING_ENDPOINT = "/hegoat-farmevents-rocessing/";
export const INSURANCE_ENDPOINT = "/hegoat-farmevents-insurance/";
export const EXPENSE_ENDPOINT = "/hegoat-farmevents-expense/";
export const INCOME_ENDPOINT = "/hegoat-farmevents-income/";
export const PURCHASE_ENDPOINT = "/hegoat-farmevents-purchase/";
export const SALES_ENDPOINT = "/hegoat-farmevents-sale/";
export const SPRAY_ENDPOINT = "/hegoat-farmevents-praying/";
export const TAG_ENDPOINT = "/hegoat-farmevents-tagging/";
export const TEMPERATURE_CHECK_ENDPOINT = "/hegoat-farmevents-temperature/";
export const TREATMENT_ENDPOINT = "/hegoat-farmevents-treatment/";
export const WEANING_ENDPOINT = "/hegoat-farmevents-weaning/";

/* Reports */
export const GOAT_REPORTS_ENDPOINT = "/hegoat-reports-goats/";
export const GOAT_BIRTHS_REPORTS_ENDPOINT = "/hegoat-reports-birth/";
export const GOAT_DEATH_REPORTS_ENDPOINT = "/hegoat-reports-death/";
export const EXPENSE_REPORTS_ENDPOINT = "/hegoat-reports-expense/";
export const FEED_OUT_REPORTS_ENDPOINT = "/hegoat-reports-feedout/";
export const INCOME_REPORTS_ENDPOINT = "/hegoat-reports-income/";
export const PURCHASE_REPORTS_ENDPOINT = "/hegoat-reports-purchase/";
export const SALES_REPORTS_ENDPOINT = "/hegoat-reports-sale/";
export const TREATMENT_REPORTS_ENDPOINT = "/hegoat-reports-treatment/";
export const WEANING_REPORTS_ENDPOINT = "/hegoat-reports-weaning/";

/* Statistics */
export const STATISTICS_ENDPOINT = "/stats/dashboard/";

/* Farmers */
export const FARMERS_ENDPOINT = "/farmers/";
