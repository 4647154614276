import { call, put, takeLatest } from "redux-saga/effects";
import { fetchExpenseReports as fetchExpenseReportsApi } from "../apis/expenseApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { expenseSlice } from "../slices/expenseSlice";

const expenseActions = expenseSlice.actions;

export function* fetchExpenseReports(actions) {
	try {
		const response = yield call(fetchExpenseReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			expenseActions.fetchExpenseReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Expense reports",
			"Failed to fetch expense reports!",
		);
		yield put(expenseActions.fetchExpenseReportsFailed());
	}
}

export function* watchFetchExpenseReports() {
	yield takeLatest(
		expenseActions.fetchExpenseReports.type,
		fetchExpenseReports,
	);
}
