import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addFarmState: { farm: null, isloading: "", errors: null },
  updateFarmState: { farm: null, isloading: "", errors: null },
  fetchFarmsState: { farms: [], totalCount: 0, isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const farmSlice = createSlice({
  name: "farm",
  initialState: initialState,
  reducers: {
    addFarm: (state) => {
      state.addFarmState.isloading = "processing";
      state.addFarmState.errors = null;
    },
    addFarmSuccess: (state, action) => {
      state.addFarmState.isloading = "success";
      state.addFarmState.farm = action.payload;
    },
    addFarmFailed: (state, action) => {
      state.addFarmState.isloading = "failed";
      state.addFarmState.errors = action.payload;
    },
    updateFarm: (state) => {
      state.updateFarmState.isloading = "processing";
      state.updateFarmState.errors = null;
    },
    updateFarmSuccess: (state, action) => {
      state.updateFarmState.isloading = "success";
      state.updateFarmState.farm = action.payload;
    },
    updateFarmFailed: (state, action) => {
      state.updateFarmState.isloading = "failed";
      state.updateFarmState.errors = action.payload;
    },
    fetchFarms: (state) => {
      state.fetchFarmsState.isloading = "processing";
      state.fetchFarmsState.errors = null;
    },
    fetchFarmsSuccess: (state, action) => {
      state.fetchFarmsState.isloading = "success";
      const { count, results } = action.payload;
      state.fetchFarmsState.farms = results;
      state.fetchFarmsState.totalCount = count;
    },
    fetchFarmsFailed: (state, action) => {
      state.fetchFarmsState.isloading = "failed";
      state.fetchFarmsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddFarm(state) {
      state.addFarmState = { farm: null, isloading: "", errors: null };
    },
    resetUpdateFarm(state) {
      state.updateFarmState = { farm: null, isloading: "", errors: null };
    },
  },
});

export { farmSlice };
