import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { FARMERS_ENDPOINT } from "../../services/api/urls";

export const fetchFarmers = (query) => {
  let search = query && query.search;
  let page = query && query.page;
  let limit = query && query.limit;
  let offset = query && query.offset;
  let user = query && query.user;

  return apiAuthenticatedRequest(
    "GET",
    `${FARMERS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${page ? `&page=${page}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
  );
};
