import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchWeaningReportsState: { weaningReports: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const weaningSlice = createSlice({
  name: "weaningReports",
  initialState: initialState,
  reducers: {
    fetchWeaningReports: (state) => {
      state.fetchWeaningReportsState.isloading = "processing";
      state.fetchWeaningReportsState.errors = null;
    },
    fetchWeaningReportsSuccess: (state, action) => {
      console.log("fetchWeaningReportsSuccess action is ", action);
      state.fetchWeaningReportsState.isloading = "success";
      state.fetchWeaningReportsState.weaningReports = action.payload;
    },
    fetchWeaningReportsFailed: (state, action) => {
      state.fetchWeaningReportsState.isloading = "failed";
      state.fetchWeaningReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { weaningSlice };
