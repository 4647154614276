import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { PRODUCTS_ENDPOINT } from "../../services/api/urls";

export const addProduct = (data) => {
	return apiAuthenticatedRequest("POST", PRODUCTS_ENDPOINT, data);
};

export const updateProduct = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${PRODUCTS_ENDPOINT}${id}/`, data);
};

export const fetchProducts = (query) => {
	let search = query && query.search;
	let page = query && query.page;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let product_type = query && query.product_type;

	return apiAuthenticatedRequest(
		"GET",
		`${PRODUCTS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${product_type ? `&product_type=${product_type}` : ""}
		${page ? `&page=${page}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
