import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAddressBookState: { addressBook: null, isloading: "", errors: null },
  updateAddressBookState: { addressBook: null, isloading: "", errors: null },
  fetchAddressBooksState: { addressBooks: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const addressBookSlice = createSlice({
  name: "addressBook",
  initialState: initialState,
  reducers: {
    addAddressBook: (state) => {
      state.addAddressBookState.isloading = "processing";
      state.addAddressBookState.errors = null;
    },
    addAddressBookSuccess: (state, action) => {
      state.addAddressBookState.isloading = "success";
      state.addAddressBookState.paddock = action.payload;
    },
    addAddressBookFailed: (state, action) => {
      state.addAddressBookState.isloading = "failed";
      state.addAddressBookState.errors = action.payload;
    },
    updateAddressBook: (state) => {
      state.updateAddressBookState.isloading = "processing";
      state.updateAddressBookState.errors = null;
    },
    updateAddressBookSuccess: (state, action) => {
      state.updateAddressBookState.isloading = "success";
      state.updateAddressBookState.paddock = action.payload;
    },
    updateAddressBookFailed: (state, action) => {
      state.updateAddressBookState.isloading = "failed";
      state.updateAddressBookState.errors = action.payload;
    },
    fetchAddressBooks: (state) => {
      state.fetchAddressBooksState.isloading = "processing";
      state.fetchAddressBooksState.errors = null;
    },
    fetchAddressBooksSuccess: (state, action) => {
      console.log("fetchAddressBooksSuccess action is ", action);
      state.fetchAddressBooksState.isloading = "success";
      state.fetchAddressBooksState.paddocks = action.payload;
    },
    fetchAddressBooksFailed: (state, action) => {
      state.fetchAddressBooksState.isloading = "failed";
      state.fetchAddressBooksState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddAddressBook(state) {
      state.addAddressBookState = {
        addressBook: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateAddressBook(state) {
      state.updateAddressBookState = {
        addressBook: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { addressBookSlice };
