import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { ADDRESS_BOOK_ENDPOINT } from "../../services/api/urls";

export const addAddressBook = (data) => {
	return apiAuthenticatedRequest("POST", ADDRESS_BOOK_ENDPOINT, data);
};

export const updateAddressBook = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${ADDRESS_BOOK_ENDPOINT}${id}/`, data);
};

export const fetchAddressBooks = (query) => {
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;

	return apiAuthenticatedRequest(
		"GET",
		`${ADDRESS_BOOK_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
