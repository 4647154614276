import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchGoatDeathReportsState: { deathReports: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const deathSlice = createSlice({
  name: "deathReports",
  initialState: initialState,
  reducers: {
    fetchGoatDeathReports: (state) => {
      state.fetchGoatDeathReportsState.isloading = "processing";
      state.fetchGoatDeathReportsState.errors = null;
    },
    fetchGoatDeathReportsSuccess: (state, action) => {
      console.log("fetchGoatDeathReportsSuccess action is ", action);
      state.fetchGoatDeathReportsState.isloading = "success";
      state.fetchGoatDeathReportsState.deathReports = action.payload;
    },
    fetchGoatDeathReportsFailed: (state, action) => {
      state.fetchGoatDeathReportsState.isloading = "failed";
      state.fetchGoatDeathReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { deathSlice };
