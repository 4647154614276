import { apiAuthenticatedRequest } from "../../../services/api/apiRequest";
import { TREATMENT_ENDPOINT } from "../../../services/api/urls";

export const addTreatment = (data) => {
	return apiAuthenticatedRequest("POST", TREATMENT_ENDPOINT, data);
};

export const updateTreatment = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${TREATMENT_ENDPOINT}${id}/`, data);
};

export const fetchTreatments = (query) => {
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let farm = query && query.farm;
	let paddock = query && query.paddock;
	let breed = query && query.breed;

	return apiAuthenticatedRequest(
		"GET",
		`${TREATMENT_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&paddock__farm__user=${user}` : ""}
		${farm ? `&farm=${farm}` : ""}
		${paddock ? `&paddock=${paddock}` : ""}
		${breed ? `&breed=${breed}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
