import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import * as serviceWorker from "./serviceWorker";

import "./styles/app.less";

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById("root"),
);

serviceWorker.unregister();
