import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchFeedOutReportsState: {
    feedoutReports: [],
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const feedoutSlice = createSlice({
  name: "feedoutReports",
  initialState: initialState,
  reducers: {
    fetchFeedOutReports: (state) => {
      state.fetchFeedOutReportsState.isloading = "processing";
      state.fetchFeedOutReportsState.errors = null;
    },
    fetchFeedOutReportsSuccess: (state, action) => {
      console.log("fetchFeedOutReportsSuccess action is ", action);
      state.fetchFeedOutReportsState.isloading = "success";
      state.fetchFeedOutReportsState.feedoutReports = action.payload;
    },
    fetchFeedOutReportsFailed: (state, action) => {
      state.fetchFeedOutReportsState.isloading = "failed";
      state.fetchFeedOutReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { feedoutSlice };
