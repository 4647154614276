import { call, put, takeLatest } from "redux-saga/effects";
import { fetchStatistics as fetchStatisticsApi } from "../apis/statisticsApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { statisticsSlice } from "../slices/statisticsSlice";

const statisticsActions = statisticsSlice.actions;

export function* fetchStatistics(actions) {
  try {
    const response = yield call(fetchStatisticsApi, {
      ...actions.payload,
      user: getUser().id,
    });
    const resp = response && response.data;
    console.log("Resp: ", resp);
    yield put(statisticsActions.fetchStatisticsSuccess(resp));
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Statistics",
      "Failed to fetch statistics!",
    );
    yield put(statisticsActions.fetchStatisticsFailed());
  }
}

export function* watchFetchStatistics() {
  yield takeLatest(statisticsActions.fetchStatistics.type, fetchStatistics);
}
