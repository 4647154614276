import { call, put, takeLatest } from "redux-saga/effects";
import { fetchGoatReports as fetchGoatReportsApi } from "../apis/goatApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { goatSlice } from "../slices/goatSlice";

const goatActions = goatSlice.actions;

export function* fetchGoatReports(actions) {
	try {
		const response = yield call(fetchGoatReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			goatActions.fetchGoatReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Goats reports",
			"Failed to fetch goats reports!",
		);
		yield put(goatActions.fetchGoatReportsFailed());
	}
}

export function* watchFetchGoatReports() {
	yield takeLatest(goatActions.fetchGoatReports.type, fetchGoatReports);
}
