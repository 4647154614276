import { call, put, takeLeading } from "redux-saga/effects";
import { login as loginApi } from "./api";
import { storeLocalState, clearLocalState } from "../../services/app-storage";
import { authSlice } from "./slice";

const authActions = authSlice.actions;

export function* login(actions) {
	try {
		console.log("login has been triggered ", actions);
		const { email, password } = actions.payload;

		const response = yield call(loginApi, { email, password });

		console.log("response 2 ", response);
		const resp = response && response.data;

		storeLocalState("mefarm-farmer-access", resp);

		yield put(authActions.loginSuccess(resp));
		yield put(authActions.getUserDetails());
	} catch (e) {
		const message =
			(e && e.response && e.response.data && e.response.data.error) ||
			"Invalid Credentials";
		yield put(authActions.loginFailed(message));
	}
}

export function* logout() {
	// clear localStorage
	clearLocalState("mefarm-farmer-access");

	yield put(authActions.logout());

	// redirect to landing page
	window.location.href = "/";
}

export function* watchLogin() {
	yield takeLeading(authActions.loginRequest.type, login);
}

export function* watchLogout() {
	yield takeLeading(authActions.logout.type, logout);
}
