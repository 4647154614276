import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addSale as addSaleApi,
  updateSale as updateSaleApi,
  fetchSales as fetchSalesApi,
} from "../apis/saleApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { saleSlice } from "../slices/saleSlice";

const goatActions = saleSlice.actions;

export function* addSale(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addSaleApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addSaleSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Sales",
      "Goat Sales Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) || "Failed to add sale";
    yield put(goatActions.addSaleFailed(message));
  }
}

export function* updateSale(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateSaleApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateSaleSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Sales",
      "Goat Sales Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update sale";
    yield put(goatActions.updateSaleFailed(message));
  }
}

export function* fetchSales(actions) {
  try {
    const response = yield call(fetchSalesApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchSalesSuccess(resp && resp.results, resp && resp.count),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Sales",
      "Failed to fetch goat sales!",
    );
    yield put(goatActions.fetchSalesFailed());
  }
}

export function* watchAddSale() {
  yield takeLeading(goatActions.addSale.type, addSale);
}

export function* watchUpdateSale() {
  yield takeLeading(goatActions.updateSale.type, updateSale);
}

export function* watchFetchSales() {
  yield takeLatest(goatActions.fetchSales.type, fetchSales);
}
