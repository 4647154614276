import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addTreatmentState: { treatment: null, isloading: "", errors: null },
  updateTreatmentState: { treatment: null, isloading: "", errors: null },
  fetchTreatmentsState: { treatments: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const treatmentSlice = createSlice({
  name: "treatment",
  initialState: initialState,
  reducers: {
    addTreatment: (state) => {
      state.addTreatmentState.isloading = "processing";
      state.addTreatmentState.errors = null;
    },
    addTreatmentSuccess: (state, action) => {
      state.addTreatmentState.isloading = "success";
      state.addTreatmentState.treatment = action.payload;
    },
    addTreatmentFailed: (state, action) => {
      state.addTreatmentState.isloading = "failed";
      state.addTreatmentState.errors = action.payload;
    },
    updateTreatment: (state) => {
      state.updateTreatmentState.isloading = "processing";
      state.updateTreatmentState.errors = null;
    },
    updateTreatmentSuccess: (state, action) => {
      state.updateTreatmentState.isloading = "success";
      state.updateTreatmentState.treatment = action.payload;
    },
    updateTreatmentFailed: (state, action) => {
      state.updateTreatmentState.isloading = "failed";
      state.updateTreatmentState.errors = action.payload;
    },
    fetchTreatments: (state) => {
      state.fetchTreatmentsState.isloading = "processing";
      state.fetchTreatmentsState.errors = null;
    },
    fetchTreatmentsSuccess: (state, action) => {
      console.log("fetchTreatmentsSuccess action is ", action);
      state.fetchTreatmentsState.isloading = "success";
      state.fetchTreatmentsState.treatments = action.payload;
    },
    fetchTreatmentsFailed: (state, action) => {
      state.fetchTreatmentsState.isloading = "failed";
      state.fetchTreatmentsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddTreatment(state) {
      state.addTreatmentState = {
        treatment: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateTreatment(state) {
      state.updateTreatmentState = {
        treatment: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { treatmentSlice };
