// import { combineReducers } from "redux";
import { authSlice } from "./containers/Auth/slice";
import { farmSlice } from "./containers/MyFarm/slice";
import { paddockSlice } from "./containers/Paddock/slice";
import { productSlice } from "./containers/Product/slice";
import { productCategorySlice } from "./containers/ProductCategory/slice";
import { breedSlice } from "./containers/Breed/slice";
import { goatSlice } from "./containers/Goat/slice";
import { addressBookSlice } from "./containers/AddressBook/slice";
import { accountsSlice } from "./containers/Accounts/slice";

import { feedoutSlice } from "./containers/Events/slices/feedoutSlice";
import { birthSlice } from "./containers/Events/slices/birthSlice";
import { deathSlice } from "./containers/Events/slices/deathSlice";
import { insuranceSlice } from "./containers/Events/slices/insuranceSlice";
import { castrationSlice } from "./containers/Events/slices/castrationSlice";
import { movementSlice } from "./containers/Events/slices/movementSlice";
import { neweventSlice } from "./containers/Events/slices/neweventSlice";
import { processingSlice } from "./containers/Events/slices/processingSlice";
import { purchaseSlice } from "./containers/Events/slices/purchaseSlice";
import { saleSlice } from "./containers/Events/slices/saleSlice";
import { spraySlice } from "./containers/Events/slices/spraySlice";
import { tagSlice } from "./containers/Events/slices/tagSlice";
import { temperaturecheckSlice } from "./containers/Events/slices/temperaturecheckSlice";
import { treatmentSlice } from "./containers/Events/slices/treatmentSlice";
import { weaningSlice } from "./containers/Events/slices/weaningSlice";

import { goatSlice as goatReportSlice } from "./containers/Reports/slices/goatSlice";
import { incomeSlice as incomeReportSlice } from "./containers/Reports/slices/incomeSlice";
import { treatmentSlice as treatmentReportSlice } from "./containers/Reports/slices/treatmentSlice";
import { expenseSlice as expenseReportSlice } from "./containers/Reports/slices/expenseSlice";
import { purchaseSlice as purchaseReportSlice } from "./containers/Reports/slices/purchaseSlice";
import { weaningSlice as weaningReportSlice } from "./containers/Reports/slices/weaningSlice";
import { saleSlice as saleReportSlice } from "./containers/Reports/slices/saleSlice";
import { deathSlice as deathReportSlice } from "./containers/Reports/slices/deathSlice";
import { birthSlice as birthReportSlice } from "./containers/Reports/slices/birthSlice";
import { feedoutSlice as feedoutReportSlice } from "./containers/Reports/slices/feedoutSlice";

import { statisticsSlice } from "./containers/Dashboard/slices/statisticsSlice";
import { farmerSlice } from "./containers/Farmers/slice";

const rootReducer = {
  auth: authSlice.reducer,
  farm: farmSlice.reducer,
  paddock: paddockSlice.reducer,
  product: productSlice.reducer,
  productCategory: productCategorySlice.reducer,
  breed: breedSlice.reducer,
  goat: goatSlice.reducer,
  addressBook: addressBookSlice.reducer,
  accounts: accountsSlice.reducer,

  birth: birthSlice.reducer,
  feedout: feedoutSlice.reducer,
  death: deathSlice.reducer,
  insurance: insuranceSlice.reducer,
  castration: castrationSlice.reducer,
  movement: movementSlice.reducer,
  newevent: neweventSlice.reducer,
  processing: processingSlice.reducer,
  purchase: purchaseSlice.reducer,
  sale: saleSlice.reducer,
  spray: spraySlice.reducer,
  tag: tagSlice.reducer,
  temperaturecheck: temperaturecheckSlice.reducer,
  treatment: treatmentSlice.reducer,
  weaning: weaningSlice.reducer,

  goatReports: goatReportSlice.reducer,
  birthReports: birthReportSlice.reducer,
  deathReports: deathReportSlice.reducer,
  incomeReports: incomeReportSlice.reducer,
  expenseReports: expenseReportSlice.reducer,
  purchaseReports: purchaseReportSlice.reducer,
  weaningReports: weaningReportSlice.reducer,
  treatmentReports: treatmentReportSlice.reducer,
  saleReports: saleReportSlice.reducer,
  feedoutReports: feedoutReportSlice.reducer,

  statistics: statisticsSlice.reducer,

  farmer: farmerSlice.reducer,
};

export default rootReducer;
