import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { FARMS_ENDPOINT } from "../../services/api/urls";

export const addFarm = (data) => {
  return apiAuthenticatedRequest("POST", FARMS_ENDPOINT, data);
};

export const updateFarm = (id, data) => {
  return apiAuthenticatedRequest("PUT", `${FARMS_ENDPOINT}${id}/`, data);
};

export const fetchFarms = (query) => {
  let search = query && query.search;
  let page = query && query.page;
  let limit = query && query.limit;
  let offset = query && query.offset;
  let user = query && query.user;

  return apiAuthenticatedRequest(
    "GET",
    `${FARMS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${page ? `&page=${page}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
  );
};
