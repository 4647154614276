import { apiAuthenticatedRequest } from "../../../services/api/apiRequest";
import { PURCHASE_REPORTS_ENDPOINT } from "../../../services/api/urls";

export const fetchPurchaseReports = (query) => {
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let farm = query && query.farm;
	let paddock = query && query.paddock;
	let breed = query && query.breed;

	return apiAuthenticatedRequest(
		"GET",
		`${PURCHASE_REPORTS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${farm ? `&farm=${farm}` : ""}
		${paddock ? `&paddock=${paddock}` : ""}
		${breed ? `&breed=${breed}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
