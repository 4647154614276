import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { notificationConfig, messageConfig } from "./util/config";
import checkAuth from "./util/helpers/checkAuth";
import { Loader } from "./components/Spinner";
import Routes from "./private-routes";

import "antd/dist/antd.less";

//function to retry lazy imports up to x times if it fails, before it errors out
const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

// code splitting...
const AuthContainerPromise = import("./containers/Auth/components");
const AuthContainer = lazy(() => retry(() => AuthContainerPromise));

const NotfoundPromise = import("./containers/NotFound");
const Notfound = lazy(() => retry(() => NotfoundPromise));

// eslint-disable-line no-use-before-define
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/" }} />
      )
    }
  />
);

export default class App extends React.Component {
  render() {
    messageConfig();
    notificationConfig();

    return (
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <div>
            <Switch>
              <Route path="/" exact component={AuthContainer} />
              <Route path="/app" component={Routes} />
              <PrivateRoute path="/test" exact component={Notfound} />
            </Switch>
          </div>
        </Suspense>
      </BrowserRouter>
    );
  }
}
