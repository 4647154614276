import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addProcessing as addProcessingApi,
  updateProcessing as updateProcessingApi,
  fetchProcessings as fetchProcessingsApi,
} from "../apis/processingApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { processingSlice } from "../slices/processingSlice";

const goatActions = processingSlice.actions;

export function* addProcessing(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addProcessingApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addProcessingSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Processing",
      "Goat Processing Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add processing";
    yield put(goatActions.addProcessingFailed(message));
  }
}

export function* updateProcessing(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateProcessingApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateProcessingSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Processing",
      "Goat Processing Update Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add processing";
    yield put(goatActions.updateProcessingFailed(message));
  }
}

export function* fetchProcessings(actions) {
  try {
    const response = yield call(fetchProcessingsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchProcessingsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Processings",
      "Failed to fetch goat processings!",
    );
    yield put(goatActions.fetchProcessingsFailed());
  }
}

export function* watchAddProcessing() {
  yield takeLeading(goatActions.addProcessing.type, addProcessing);
}

export function* watchUpdateProcessing() {
  yield takeLeading(goatActions.updateProcessing.type, updateProcessing);
}

export function* watchFetchProcessings() {
  yield takeLatest(goatActions.fetchProcessings.type, fetchProcessings);
}
