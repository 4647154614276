import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userState: { user: null, isloading: "", errors: null },
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    loginRequest: (state) => {
      state.userState.isloading = "processing";
      state.userState.errors = null;
    },
    loginSuccess: (state, action) => {
      state.userState.isloading = "success";
      state.userState.user = action.payload;
    },
    loginFailed: (state, action) => {
      state.userState.isloading = "failed";
      state.userState.errors = action.payload;
    },
    logout: (state) => {
      state.userState.user = null;
    },
  },
});

export { authSlice };
