import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addPaddock as addPaddockApi,
  updatePaddock as updatePaddockApi,
  fetchPaddocks as fetchPaddocksApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { paddockSlice } from "./slice";

const paddockActions = paddockSlice.actions;

export function* addPaddock(actions) {
  try {
    const { name, farm } = actions.payload;

    const response = yield call(addPaddockApi, {
      name,
      farm,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(paddockActions.addPaddockSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Add Paddock",
      "Paddock Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add paddock";
    yield put(paddockActions.addPaddockFailed(message));
  }
}

export function* updatePaddock(actions) {
  try {
    const { id, name, farm } = actions.payload;

    const response = yield call(updatePaddockApi, id, {
      name,
      farm,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(paddockActions.updatePaddockSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Paddock",
      "Paddock updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add paddock";
    yield put(paddockActions.updatePaddockFailed(message));
  }
}

export function* fetchPaddocks(actions) {
  try {
    const response = yield call(fetchPaddocksApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    console.log("response is ", response);
    yield put(
      paddockActions.fetchPaddocksSuccess({
        results: resp && resp.results,
        count: resp && resp.count,
      }),
    );
  } catch (e) {
    OpenNotificationWithIcon("error", "Fetch Farms", "Failed to fetch farms!");
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to add farm";
    yield put(paddockActions.fetchPaddocksFailed(message));
  }
}

export function* watchAddPaddock() {
  yield takeLeading(paddockActions.addPaddock.type, addPaddock);
}

export function* watchUpdatePaddock() {
  yield takeLeading(paddockActions.updatePaddock.type, updatePaddock);
}

export function* watchFetchPaddocks() {
  yield takeLatest(paddockActions.fetchPaddocks.type, fetchPaddocks);
}
