import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchStatisticsState: { statistics: [], isloading: "", errors: null },
};

const statisticsSlice = createSlice({
  name: "statistics",
  initialState: initialState,
  reducers: {
    fetchStatistics: (state) => {
      state.fetchStatisticsState.isloading = "processing";
      state.fetchStatisticsState.errors = null;
    },
    fetchStatisticsSuccess: (state, action) => {
      state.fetchStatisticsState.isloading = "success";
      state.fetchStatisticsState.statistics = action.payload;
    },
    fetchStatisticsFailed: (state, action) => {
      state.fetchStatisticsState.isloading = "failed";
      state.fetchStatisticsState.errors = action.payload;
    },
  },
});

export { statisticsSlice };
