import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addFeedOut as addFeedOutApi,
  updateFeedOut as updateFeedOutApi,
  fetchFeedOuts as fetchFeedOutsApi,
} from "../apis/feedoutApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { feedoutSlice } from "../slices/feedoutSlice";

const feedOutActions = feedoutSlice.actions;

export function* addFeedOut(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addFeedOutApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(feedOutActions.addFeedOutSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat FeedOut",
      "Goat FeedOut Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add feedout";
    yield put(feedOutActions.addFeedOutFailed(message));
  }
}

export function* updateFeedOut(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateFeedOutApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(feedOutActions.updateFeedOutSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat FeedOut",
      "Goat FeedOut Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add feedout";
    yield put(feedOutActions.updateFeedOutFailed(message));
  }
}

export function* fetchFeedOuts(actions) {
  try {
    const response = yield call(fetchFeedOutsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      feedOutActions.fetchFeedOutsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch FeedOut",
      "Failed to fetch goat feedouts!",
    );
    yield put(feedOutActions.fetchFeedOutsFailed());
  }
}

export function* watchAddFeedOut() {
  yield takeLeading(feedOutActions.addFeedOut.type, addFeedOut);
}

export function* watchUpdateFeedOut() {
  yield takeLeading(feedOutActions.updateFeedOut.type, updateFeedOut);
}

export function* watchFetchFeedOuts() {
  yield takeLatest(feedOutActions.fetchFeedOuts.type, fetchFeedOuts);
}
