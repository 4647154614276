import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGoatMovementState: { movement: null, isloading: "", errors: null },
  updateGoatMovementState: { movement: null, isloading: "", errors: null },
  fetchGoatMovementsState: { movements: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const movementSlice = createSlice({
  name: "movement",
  initialState: initialState,
  reducers: {
    addGoatMovement: (state) => {
      state.addGoatMovementState.isloading = "processing";
      state.addGoatMovementState.errors = null;
    },
    addGoatMovementSuccess: (state, action) => {
      state.addGoatMovementState.isloading = "success";
      state.addGoatMovementState.movement = action.payload;
    },
    addGoatMovementFailed: (state, action) => {
      state.addGoatMovementState.isloading = "failed";
      state.addGoatMovementState.errors = action.payload;
    },
    updateGoatMovement: (state) => {
      state.updateGoatMovementState.isloading = "processing";
      state.updateGoatMovementState.errors = null;
    },
    updateGoatMovementSuccess: (state, action) => {
      state.updateGoatMovementState.isloading = "success";
      state.updateGoatMovementState.movement = action.payload;
    },
    updateGoatMovementFailed: (state, action) => {
      state.updateGoatMovementState.isloading = "failed";
      state.updateGoatMovementState.errors = action.payload;
    },
    fetchGoatMovements: (state) => {
      state.fetchGoatMovementsState.isloading = "processing";
      state.fetchGoatMovementsState.errors = null;
    },
    fetchGoatMovementsSuccess: (state, action) => {
      console.log("fetchGoatMovementsSuccess action is ", action);
      state.fetchGoatMovementsState.isloading = "success";
      state.fetchGoatMovementsState.movements = action.payload;
    },
    fetchGoatMovementsFailed: (state, action) => {
      state.fetchGoatMovementsState.isloading = "failed";
      state.fetchGoatMovementsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoatMovement(state) {
      state.addGoatMovementState = {
        movement: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateGoatMovement(state) {
      state.updateGoatMovementState = {
        movement: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { movementSlice };
