import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGoatCastrationState: { castration: null, isloading: "", errors: null },
  updateGoatCastrationState: { castration: null, isloading: "", errors: null },
  fetchGoatCastrationsState: { castrations: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const castrationSlice = createSlice({
  name: "castration",
  initialState: initialState,
  reducers: {
    addGoatCastration: (state) => {
      state.addGoatCastrationState.isloading = "processing";
      state.addGoatCastrationState.errors = null;
    },
    addGoatCastrationSuccess: (state, action) => {
      state.addGoatCastrationState.isloading = "success";
      state.addGoatCastrationState.castration = action.payload;
    },
    addGoatCastrationFailed: (state, action) => {
      state.addGoatCastrationState.isloading = "failed";
      state.addGoatCastrationState.errors = action.payload;
    },
    updateGoatCastration: (state) => {
      state.updateGoatCastrationState.isloading = "processing";
      state.updateGoatCastrationState.errors = null;
    },
    updateGoatCastrationSuccess: (state, action) => {
      state.updateGoatCastrationState.isloading = "success";
      state.updateGoatCastrationState.castration = action.payload;
    },
    updateGoatCastrationFailed: (state, action) => {
      state.updateGoatCastrationState.isloading = "failed";
      state.updateGoatCastrationState.errors = action.payload;
    },
    fetchGoatCastrations: (state) => {
      state.fetchGoatCastrationsState.isloading = "processing";
      state.fetchGoatCastrationsState.errors = null;
    },
    fetchGoatCastrationsSuccess: (state, action) => {
      console.log("fetchGoatCastrationsSuccess action is ", action);
      state.fetchGoatCastrationsState.isloading = "success";
      state.fetchGoatCastrationsState.castrations = action.payload;
    },
    fetchGoatCastrationsFailed: (state, action) => {
      state.fetchGoatCastrationsState.isloading = "failed";
      state.fetchGoatCastrationsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoatCastration(state) {
      state.addGoatCastrationState = {
        castration: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateGoatCastration(state) {
      state.updateGoatCastrationState = {
        castration: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { castrationSlice };
