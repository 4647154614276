import { call, put, takeLatest } from "redux-saga/effects";
import { fetchFarmers as fetchFarmersApi } from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { farmerSlice } from "./slice";

const farmerActions = farmerSlice.actions;

export function* fetchFarmers(actions) {
  try {
    const response = yield call(fetchFarmersApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      farmerActions.fetchFarmersSuccess({
        results: resp && resp.results,
        count: resp && resp.count,
      }),
    );
  } catch (e) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Farmers",
      "Failed to fetch farmers!",
    );
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to add farm";
    yield put(farmerActions.fetchFarmersFailed(message));
  }
}

export function* watchFetchFarmers() {
  yield takeLatest(farmerActions.fetchFarmers.type, fetchFarmers);
}
