import { apiAuthenticatedRequest } from "../../../services/api/apiRequest";
import { GOAT_CASTRATION_ENDPOINT } from "../../../services/api/urls";

export const addGoatCastration = (data) => {
	return apiAuthenticatedRequest("POST", GOAT_CASTRATION_ENDPOINT, data);
};

export const updateGoatCastration = (id, data) => {
	return apiAuthenticatedRequest(
		"PUT",
		`${GOAT_CASTRATION_ENDPOINT}${id}/`,
		data,
	);
};

export const fetchGoatCastrations = (query) => {
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let farm = query && query.farm;
	let paddock = query && query.paddock;
	let breed = query && query.breed;

	return apiAuthenticatedRequest(
		"GET",
		`${GOAT_CASTRATION_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&paddock__farm__user=${user}` : ""}
		${farm ? `&farm=${farm}` : ""}
		${paddock ? `&paddock=${paddock}` : ""}
		${breed ? `&breed=${breed}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
