import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addProduct as addProductApi,
  updateProduct as updateProductApi,
  fetchProducts as fetchProductsApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { productSlice } from "./slice";

const productActions = productSlice.actions;

export function* addProduct(actions) {
  try {
    const { name, price, product_type } = actions.payload;

    const response = yield call(addProductApi, {
      name,
      price,
      product_type,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(productActions.addProductSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Add Product",
      "Product Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add product";
    yield put(productActions.addProductFailed(message));
  }
}

export function* updateProduct(actions) {
  try {
    const { id, name, price, product_type } = actions.payload;

    const response = yield call(updateProductApi, id, {
      name,
      price,
      product_type,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(productActions.updateProductSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Product",
      "Product Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add product";
    yield put(productActions.updateProductFailed(message));
  }
}

export function* fetchProducts(actions) {
  try {
    const response = yield call(fetchProductsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      productActions.fetchProductsSuccess({
        results: resp && resp.results,
        count: resp && resp.count,
      }),
    );
  } catch (e) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Products",
      "Failed to fetch products!",
    );
    const message =
      (e && e.response && e.response.data && e.response.data.error) ||
      "Failed to add farm";
    yield put(productActions.fetchProductsFailed(message));
  }
}

export function* watchAddProduct() {
  yield takeLeading(productActions.addProduct.type, addProduct);
}

export function* watchUpdateProduct() {
  yield takeLeading(productActions.updateProduct.type, updateProduct);
}

export function* watchFetchProducts() {
  yield takeLatest(productActions.fetchProducts.type, fetchProducts);
}
