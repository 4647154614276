import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchSaleReportsState: { saleReports: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const saleSlice = createSlice({
  name: "saleReports",
  initialState: initialState,
  reducers: {
    fetchSaleReports: (state) => {
      state.fetchSaleReportsState.isloading = "processing";
      state.fetchSaleReportsState.errors = null;
    },
    fetchSaleReportsSuccess: (state, action) => {
      console.log("fetchSaleReportsSuccess action is ", action);
      state.fetchSaleReportsState.isloading = "success";
      state.fetchSaleReportsState.saleReports = action.payload;
    },
    fetchSaleReportsFailed: (state, action) => {
      state.fetchSaleReportsState.isloading = "failed";
      state.fetchSaleReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { saleSlice };
