import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addSprayState: { spray: null, isloading: "", errors: null },
  updateSprayState: { spray: null, isloading: "", errors: null },
  fetchSpraysState: { sprays: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const spraySlice = createSlice({
  name: "spray",
  initialState: initialState,
  reducers: {
    addSpray: (state) => {
      state.addSprayState.isloading = "processing";
      state.addSprayState.errors = null;
    },
    addSpraySuccess: (state, action) => {
      state.addSprayState.isloading = "success";
      state.addSprayState.spray = action.payload;
    },
    addSprayFailed: (state, action) => {
      state.addSprayState.isloading = "failed";
      state.addSprayState.errors = action.payload;
    },
    updateSpray: (state) => {
      state.updateSprayState.isloading = "processing";
      state.updateSprayState.errors = null;
    },
    updateSpraySuccess: (state, action) => {
      state.updateSprayState.isloading = "success";
      state.updateSprayState.spray = action.payload;
    },
    updateSprayFailed: (state, action) => {
      state.updateSprayState.isloading = "failed";
      state.updateSprayState.errors = action.payload;
    },
    fetchSprays: (state) => {
      state.fetchSpraysState.isloading = "processing";
      state.fetchSpraysState.errors = null;
    },
    fetchSpraysSuccess: (state, action) => {
      console.log("fetchSpraysSuccess action is ", action);
      state.fetchSpraysState.isloading = "success";
      state.fetchSpraysState.sprays = action.payload;
    },
    fetchSpraysFailed: (state, action) => {
      state.fetchSpraysState.isloading = "failed";
      state.fetchSpraysState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddSpray(state) {
      state.addSprayState = {
        spray: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateSpray(state) {
      state.updateSprayState = {
        spray: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { spraySlice };
