import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetchIncomeReportsState: { incomeReports: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const incomeSlice = createSlice({
  name: "incomeReports",
  initialState: initialState,
  reducers: {
    fetchIncomeReports: (state) => {
      state.fetchIncomeReportsState.isloading = "processing";
      state.fetchIncomeReportsState.errors = null;
    },
    fetchIncomeReportsSuccess: (state, action) => {
      console.log("fetchIncomeReportsSuccess action is ", action);
      state.fetchIncomeReportsState.isloading = "success";
      state.fetchIncomeReportsState.incomeReports = action.payload;
    },
    fetchIncomeReportsFailed: (state, action) => {
      state.fetchIncomeReportsState.isloading = "failed";
      state.fetchIncomeReportsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
  },
});

export { incomeSlice };
