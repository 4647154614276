import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addTreatment as addTreatmentApi,
  updateTreatment as updateTreatmentApi,
  fetchTreatments as fetchTreatmentsApi,
} from "../apis/treatmentApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { treatmentSlice } from "../slices/treatmentSlice";

const goatActions = treatmentSlice.actions;

export function* addTreatment(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addTreatmentApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addTreatmentSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Treatment",
      "Goat Treatment Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add treatment";
    yield put(goatActions.addTreatmentFailed(message));
  }
}

export function* updateTreatment(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateTreatmentApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateTreatmentSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Treatment",
      "Goat Treatment Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update treatment";
    yield put(goatActions.updateTreatmentFailed(message));
  }
}

export function* fetchTreatments(actions) {
  try {
    const response = yield call(fetchTreatmentsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchTreatmentsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Treatments",
      "Failed to fetch goat treatments!",
    );
    yield put(goatActions.fetchTreatmentsFailed());
  }
}

export function* watchAddTreatment() {
  yield takeLeading(goatActions.addTreatment.type, addTreatment);
}

export function* watchUpdateTreatment() {
  yield takeLeading(goatActions.updateTreatment.type, updateTreatment);
}

export function* watchFetchTreatments() {
  yield takeLatest(goatActions.fetchTreatments.type, fetchTreatments);
}
