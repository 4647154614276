import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addWeaningState: { weaning: null, isloading: "", errors: null },
  updateWeaningState: { weaning: null, isloading: "", errors: null },
  fetchWeaningsState: { weanings: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const weaningSlice = createSlice({
  name: "weaning",
  initialState: initialState,
  reducers: {
    addWeaning: (state) => {
      state.addWeaningState.isloading = "processing";
      state.addWeaningState.errors = null;
    },
    addWeaningSuccess: (state, action) => {
      state.addWeaningState.isloading = "success";
      state.addWeaningState.weaning = action.payload;
    },
    addWeaningFailed: (state, action) => {
      state.addWeaningState.isloading = "failed";
      state.addWeaningState.errors = action.payload;
    },
    updateWeaning: (state) => {
      state.updateWeaningState.isloading = "processing";
      state.updateWeaningState.errors = null;
    },
    updateWeaningSuccess: (state, action) => {
      state.updateWeaningState.isloading = "success";
      state.updateWeaningState.weaning = action.payload;
    },
    updateWeaningFailed: (state, action) => {
      state.updateWeaningState.isloading = "failed";
      state.updateWeaningState.errors = action.payload;
    },
    fetchWeanings: (state) => {
      state.fetchWeaningsState.isloading = "processing";
      state.fetchWeaningsState.errors = null;
    },
    fetchWeaningsSuccess: (state, action) => {
      console.log("fetchWeaningsSuccess action is ", action);
      state.fetchWeaningsState.isloading = "success";
      state.fetchWeaningsState.weanings = action.payload;
    },
    fetchWeaningsFailed: (state, action) => {
      state.fetchWeaningsState.isloading = "failed";
      state.fetchWeaningsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddWeaning(state) {
      state.addWeaningState = {
        weaning: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateWeaning(state) {
      state.updateWeaningState = {
        weaning: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { weaningSlice };
