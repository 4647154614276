import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { PRODUCT_CATEGORY_ENDPOINT } from "../../services/api/urls";

export const addProductCategory = (data) => {
	return apiAuthenticatedRequest("POST", PRODUCT_CATEGORY_ENDPOINT, data);
};

export const updateProductCategory = (id, data) => {
	return apiAuthenticatedRequest(
		"PUT",
		`${PRODUCT_CATEGORY_ENDPOINT}${id}/`,
		data,
	);
};

export const fetchProductCategories = (query) => {
	let search = query && query.search;
	let page = query && query.page;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;

	return apiAuthenticatedRequest(
		"GET",
		`${PRODUCT_CATEGORY_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&user=${user}` : ""}
		${page ? `&page=${page}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
