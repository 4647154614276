import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { GOATS_ENDPOINT } from "../../services/api/urls";

export const addGoat = (data) => {
	return apiAuthenticatedRequest("POST", GOATS_ENDPOINT, data);
};

export const updateGoat = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${GOATS_ENDPOINT}${id}/`, data);
};

export const fetchGoats = (query) => {
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let farm = query && query.farm;
	let paddock = query && query.paddock;
	let breed = query && query.breed;
	let sold = query && query.sold;
	let died = query && query.died;
	let weaned = query && query.weaned;
	let goat_sold = typeof (query && query.sold) !== "undefined";
	let goat_died = typeof (query && query.died) !== "undefined";
	let goat_weaned = typeof (query && query.weaned) !== "undefined";

	return apiAuthenticatedRequest(
		"GET",
		`${GOATS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&paddock__farm__user=${user}` : ""}
		${farm ? `&farm=${farm}` : ""}
		${paddock ? `&paddock=${paddock}` : ""}
		${breed ? `&breed=${breed}` : ""}
		${goat_sold ? `&sold=${sold}` : ""}
		${goat_died ? `&died=${died}` : ""}
		${goat_weaned ? `&weaned=${weaned}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
