import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addProductCategory as addProductCategoryApi,
  updateProductCategory as updateProductCategoryApi,
  fetchProductCategories as fetchProductCategoriesApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { productCategorySlice } from "./slice";

const productCategoryActions = productCategorySlice.actions;

export function* addProductCategory(actions) {
  try {
    const { name } = actions.payload;

    const response = yield call(addProductCategoryApi, {
      name,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(productCategoryActions.addProductCategorySuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Add Product Category",
      "Product Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add product Category";
    yield put(productCategoryActions.addProductCategoryFailed(message));
  }
}

export function* updateProductCategory(actions) {
  try {
    const { id, name } = actions.payload;

    const response = yield call(updateProductCategoryApi, id, {
      name,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(productCategoryActions.updateProductCategorySuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Update Product Category",
      "Product Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update product Category";
    yield put(productCategoryActions.updateProductCategoryFailed(message));
  }
}

export function* fetchProductCategories(actions) {
  try {
    const response = yield call(fetchProductCategoriesApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      productCategoryActions.fetchProductCategoriesSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Product Categories",
      "Failed to fetch product Categories!",
    );
    yield put(productCategoryActions.fetchProductCategoriesFailed());
  }
}

export function* watchAddProductCategory() {
  yield takeLeading(
    productCategoryActions.addProductCategory.type,
    addProductCategory,
  );
}

export function* watchUpdateProductCategory() {
  yield takeLeading(
    productCategoryActions.updateProductCategory.type,
    updateProductCategory,
  );
}

export function* watchFetchProductCategories() {
  yield takeLatest(
    productCategoryActions.fetchProductCategories.type,
    fetchProductCategories,
  );
}
