import React from "react";
import { Layout, Menu } from "antd";
import {
  UserOutlined,
  VideoCameraOutlined,
  CalendarOutlined,
  AreaChartOutlined,
  WalletOutlined,
  TeamOutlined,
  SwitcherOutlined,
  DingtalkOutlined,
  FundOutlined,
  BookOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Sider } = Layout;

const menus = [
  { name: "Dashboard", path: "/app", icon: <UserOutlined /> },
  { name: "My Farm", path: "/app/myfarm", icon: <VideoCameraOutlined /> },
  { name: "Paddocks", path: "/app/paddocks", icon: <BookOutlined /> },
  { name: "Breeds", path: "/app/breeds", icon: <SwitcherOutlined /> },
  { name: "Goats", path: "/app/goats", icon: <DingtalkOutlined /> },
  {
    name: "Product categories",
    path: "/app/product-categories",
    icon: <AuditOutlined />,
  },
  { name: "Products", path: "/app/products", icon: <FundOutlined /> },
  { name: "Accounts/Revenues", path: "/app/revenues", icon: <TeamOutlined /> },
  { name: "Accounts/Expenses", path: "/app/expenses", icon: <TeamOutlined /> },
  {
    name: "Address Books",
    path: "/app/address-books",
    icon: <WalletOutlined />,
  },
  {
    name: "Events",
    path: "/app/events",
    icon: <CalendarOutlined />,
  },
  {
    name: "Reports",
    path: "/app/reports",
    icon: <AreaChartOutlined />,
  },
];

const SideBar = ({ collapsed }) => {
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">ME-FARM</div>
      <Menu theme="dark" mode="inline" defaultSelectedKeys={["1"]}>
        {menus.map((menu) => (
          <Menu.Item key={menu.name} icon={menu.icon}>
            <Link to={menu.path}>{menu.name}</Link>
          </Menu.Item>
        ))}
      </Menu>
    </Sider>
  );
};

export default SideBar;
