import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addInsuranceState: { insurance: null, isloading: "", errors: null },
  updateInsuranceState: { insurance: null, isloading: "", errors: null },
  fetchInsurancesState: { insurances: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const insuranceSlice = createSlice({
  name: "insurance",
  initialState: initialState,
  reducers: {
    addInsurance: (state) => {
      state.addInsuranceState.isloading = "processing";
      state.addInsuranceState.errors = null;
    },
    addInsuranceSuccess: (state, action) => {
      state.addInsuranceState.isloading = "success";
      state.addInsuranceState.insurance = action.payload;
    },
    addInsuranceFailed: (state, action) => {
      state.addInsuranceState.isloading = "failed";
      state.addInsuranceState.errors = action.payload;
    },
    updateInsurance: (state) => {
      state.updateInsuranceState.isloading = "processing";
      state.updateInsuranceState.errors = null;
    },
    updateInsuranceSuccess: (state, action) => {
      state.updateInsuranceState.isloading = "success";
      state.updateInsuranceState.insurance = action.payload;
    },
    updateInsuranceFailed: (state, action) => {
      state.updateInsuranceState.isloading = "failed";
      state.updateInsuranceState.errors = action.payload;
    },
    fetchInsurances: (state) => {
      state.fetchInsurancesState.isloading = "processing";
      state.fetchInsurancesState.errors = null;
    },
    fetchInsurancesSuccess: (state, action) => {
      console.log("fetchInsurancesSuccess action is ", action);
      state.fetchInsurancesState.isloading = "success";
      state.fetchInsurancesState.insurances = action.payload;
    },
    fetchInsurancesFailed: (state, action) => {
      state.fetchInsurancesState.isloading = "failed";
      state.fetchInsurancesState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoatInsurance(state) {
      state.addInsuranceState = {
        insurance: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateGoatInsurance(state) {
      state.updateInsuranceState = {
        insurance: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { insuranceSlice };
