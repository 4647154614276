import { call, put, takeLatest } from "redux-saga/effects";
import { fetchGoatDeathReports as fetchGoatDeathReportsApi } from "../apis/deathApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { deathSlice } from "../slices/deathSlice";

const goatActions = deathSlice.actions;

export function* fetchGoatDeathReports(actions) {
	try {
		const response = yield call(fetchGoatDeathReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			goatActions.fetchGoatDeathReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch Death reports",
			"Failed to fetch death reports!",
		);
		yield put(goatActions.fetchGoatDeathReportsFailed());
	}
}

export function* watchFetchGoatDeathReports() {
	yield takeLatest(
		goatActions.fetchGoatDeathReports.type,
		fetchGoatDeathReports,
	);
}
