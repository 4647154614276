import { call, put, takeLatest } from "redux-saga/effects";
import { fetchGoatBirthReports as fetchGoatBirthReportsApi } from "../apis/birthApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser } from "../../../services/api/axiosDefaults";
import { birthSlice } from "../slices/birthSlice";

const birthActions = birthSlice.actions;

export function* fetchGoatBirthReports(actions) {
	try {
		const response = yield call(fetchGoatBirthReportsApi, {
			...actions.payload,
			user: getUser().id,
		});
		const resp = response && response.data;
		yield put(
			birthActions.fetchGoatBirthReportsSuccess(
				resp && resp.results,
				resp && resp.count,
			),
		);
	} catch (error) {
		OpenNotificationWithIcon(
			"error",
			"Fetch birth reports",
			"Failed to fetch goat birth reports!",
		);
		yield put(birthActions.fetchGoatBirthReportsFailed());
	}
}

export function* watchFetchGoatBirthReports() {
	yield takeLatest(
		birthActions.fetchGoatBirthReports.type,
		fetchGoatBirthReports,
	);
}
