import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addNewEvent as addNewEventApi,
  updateNewEvent as updateNewEventApi,
  fetchNewEvents as fetchNewEventsApi,
} from "../apis/neweventApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { neweventSlice } from "../slices/neweventSlice";

const goatActions = neweventSlice.actions;

export function* addNewEvent(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addNewEventApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addNewEventSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "New Event",
      "New event Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add new event";
    yield put(goatActions.addNewEventFailed(message));
  }
}

export function* updateNewEvent(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    const response = yield call(updateNewEventApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateNewEventSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "New Event",
      "New event Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to update new event";
    yield put(goatActions.updateNewEventFailed(message));
  }
}

export function* fetchNewEvents(actions) {
  try {
    const response = yield call(fetchNewEventsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchNewEventsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch New event",
      "Failed to fetch new event!",
    );
    yield put(goatActions.fetchNewEventsFailed());
  }
}

export function* watchAddNewEvent() {
  yield takeLeading(goatActions.addNewEvent.type, addNewEvent);
}

export function* watchUpdateNewEvent() {
  yield takeLeading(goatActions.updateNewEvent.type, updateNewEvent);
}

export function* watchFetchNewEvents() {
  yield takeLatest(goatActions.fetchNewEvents.type, fetchNewEvents);
}
