import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProductState: { product: null, isloading: "", errors: null },
  updateProductState: { product: null, isloading: "", errors: null },
  fetchProductsState: { products: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    addProduct: (state) => {
      state.addProductState.isloading = "processing";
      state.addProductState.errors = null;
    },
    addProductSuccess: (state, action) => {
      state.addProductState.isloading = "success";
      state.addProductState.product = action.payload;
    },
    addProductFailed: (state, action) => {
      state.addProductState.isloading = "failed";
      state.addProductState.errors = action.payload;
    },
    updateProduct: (state) => {
      state.updateProductState.isloading = "processing";
      state.updateProductState.errors = null;
    },
    updateProductSuccess: (state, action) => {
      state.updateProductState.isloading = "success";
      state.updateProductState.product = action.payload;
    },
    updateProductFailed: (state, action) => {
      state.updateProductState.isloading = "failed";
      state.updateProductState.errors = action.payload;
    },
    fetchProducts: (state) => {
      state.fetchProductsState.isloading = "processing";
      state.fetchProductsState.errors = null;
    },
    fetchProductsSuccess: (state, action) => {
      const { results, count } = action.payload;
      state.fetchProductsState.isloading = "success";
      state.fetchProductsState.products = results;
      state.fetchProductsState.totalCount = count;
    },
    fetchProductsFailed: (state, action) => {
      state.fetchProductsState.isloading = "failed";
      state.fetchProductsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddProduct(state) {
      state.addProductState = { product: null, isloading: "", errors: null };
    },
    resetUpdateProduct(state) {
      state.updateProductState = { product: null, isloading: "", errors: null };
    },
  },
});

export { productSlice };
