import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addGoatDeathState: { death: null, isloading: "", errors: null },
  updateGoatDeathState: { death: null, isloading: "", errors: null },
  fetchGoatDeathsState: { deaths: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const deathSlice = createSlice({
  name: "death",
  initialState: initialState,
  reducers: {
    addGoatDeath: (state) => {
      state.addGoatDeathState.isloading = "processing";
      state.addGoatDeathState.errors = null;
    },
    addGoatDeathSuccess: (state, action) => {
      state.addGoatDeathState.isloading = "success";
      state.addGoatDeathState.death = action.payload;
    },
    addGoatDeathFailed: (state, action) => {
      state.addGoatDeathState.isloading = "failed";
      state.addGoatDeathState.errors = action.payload;
    },
    updateGoatDeath: (state) => {
      state.updateGoatDeathState.isloading = "processing";
      state.updateGoatDeathState.errors = null;
    },
    updateGoatDeathSuccess: (state, action) => {
      state.updateGoatDeathState.isloading = "success";
      state.updateGoatDeathState.death = action.payload;
    },
    updateGoatDeathFailed: (state, action) => {
      state.updateGoatDeathState.isloading = "failed";
      state.updateGoatDeathState.errors = action.payload;
    },
    fetchGoatDeaths: (state) => {
      state.fetchGoatDeathsState.isloading = "processing";
      state.fetchGoatDeathsState.errors = null;
    },
    fetchGoatDeathsSuccess: (state, action) => {
      console.log("fetchGoatDeathsSuccess action is ", action);
      state.fetchGoatDeathsState.isloading = "success";
      state.fetchGoatDeathsState.deaths = action.payload;
    },
    fetchGoatDeathsFailed: (state, action) => {
      state.fetchGoatDeathsState.isloading = "failed";
      state.fetchGoatDeathsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddGoatDeath(state) {
      state.addGoatDeathState = { death: null, isloading: "", errors: null };
    },
    resetUpdateGoatDeath(state) {
      state.updateGoatDeathState = { death: null, isloading: "", errors: null };
    },
  },
});

export { deathSlice };
