import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProcessingState: { processing: null, isloading: "", errors: null },
  updateProcessingState: { processing: null, isloading: "", errors: null },
  fetchProcessingsState: { processings: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const processingSlice = createSlice({
  name: "processing",
  initialState: initialState,
  reducers: {
    addProcessing: (state) => {
      state.addProcessingState.isloading = "processing";
      state.addProcessingState.errors = null;
    },
    addProcessingSuccess: (state, action) => {
      state.addProcessingState.isloading = "success";
      state.addProcessingState.processing = action.payload;
    },
    addProcessingFailed: (state, action) => {
      state.addProcessingState.isloading = "failed";
      state.addProcessingState.errors = action.payload;
    },
    updateProcessing: (state) => {
      state.updateProcessingState.isloading = "processing";
      state.updateProcessingState.errors = null;
    },
    updateProcessingSuccess: (state, action) => {
      state.updateProcessingState.isloading = "success";
      state.updateProcessingState.processing = action.payload;
    },
    updateProcessingFailed: (state, action) => {
      state.updateProcessingState.isloading = "failed";
      state.updateProcessingState.errors = action.payload;
    },
    fetchProcessings: (state) => {
      state.fetchProcessingsState.isloading = "processing";
      state.fetchProcessingsState.errors = null;
    },
    fetchProcessingsSuccess: (state, action) => {
      console.log("fetchProcessingsSuccess action is ", action);
      state.fetchProcessingsState.isloading = "success";
      state.fetchProcessingsState.processings = action.payload;
    },
    fetchProcessingsFailed: (state, action) => {
      state.fetchProcessingsState.isloading = "failed";
      state.fetchProcessingsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddProcessing(state) {
      state.addProcessingState = {
        processing: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateProcessing(state) {
      state.updateProcessingState = {
        processing: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { processingSlice };
