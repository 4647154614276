import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addGoat as addGoatApi,
  fetchGoats as fetchGoatsApi,
  updateGoat as updateGoatApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { goatSlice } from "./slice";

const goatActions = goatSlice.actions;

export function* addGoat(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addGoatApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addGoatSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Details",
      "Goat details Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) || "Failed to add goat";
    yield put(goatActions.addGoatFailed(message));
  }
}

export function* updateGoat(actions) {
  try {
    let { id } = actions.payload;
    console.log("actions updateGoat ==> ", actions);
    console.log("actions id ", id);
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateGoatApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateGoatSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Details",
      "Goat details Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to updated goat";
    yield put(goatActions.updateGoatFailed(message));
  }
}

export function* fetchGoats(actions) {
  try {
    const response = yield call(fetchGoatsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchGoatsSuccess(resp && resp.results, resp && resp.count),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Farms",
      "Failed to fetch goat details!",
    );
    yield put(goatActions.fetchGoatsFailed());
  }
}

export function* watchAddGoat() {
  yield takeLeading(goatActions.addGoat.type, addGoat);
}

export function* watchUpdateGoat() {
  yield takeLeading(goatActions.updateGoat.type, updateGoat);
}

export function* watchFetchGoats() {
  yield takeLatest(goatActions.fetchGoats.type, fetchGoats);
}
