import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addPaddockState: { paddock: null, isloading: "", errors: null },
  updatePaddockState: { paddock: null, isloading: "", errors: null },
  fetchPaddocksState: {
    paddocks: [],
    totalCount: 0,
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const paddockSlice = createSlice({
  name: "paddock",
  initialState: initialState,
  reducers: {
    addPaddock: (state) => {
      state.addPaddockState.isloading = "processing";
      state.addPaddockState.errors = null;
    },
    addPaddockSuccess: (state, action) => {
      state.addPaddockState.isloading = "success";
      state.addPaddockState.paddock = action.payload;
    },
    addPaddockFailed: (state, action) => {
      state.addPaddockState.isloading = "failed";
      state.addPaddockState.errors = action.payload;
    },
    updatePaddock: (state) => {
      state.updatePaddockState.isloading = "processing";
      state.updatePaddockState.errors = null;
    },
    updatePaddockSuccess: (state, action) => {
      state.updatePaddockState.isloading = "success";
      state.updatePaddockState.paddock = action.payload;
    },
    updatePaddockFailed: (state, action) => {
      state.updatePaddockState.isloading = "failed";
      state.updatePaddockState.errors = action.payload;
    },
    fetchPaddocks: (state) => {
      state.fetchPaddocksState.isloading = "processing";
      state.fetchPaddocksState.errors = null;
    },
    fetchPaddocksSuccess: (state, action) => {
      console.log("fetchPaddocksSuccess action is ", action);
      const { results, count } = action.payload;
      state.fetchPaddocksState.isloading = "success";
      state.fetchPaddocksState.paddocks = results;
      state.fetchPaddocksState.totalCount = count;
    },
    fetchPaddocksFailed: (state, action) => {
      state.fetchPaddocksState.isloading = "failed";
      state.fetchPaddocksState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddPaddock(state) {
      state.addPaddockState = { paddock: null, isloading: "", errors: null };
    },
    resetUpdatePaddock(state) {
      state.updatePaddockState = { paddock: null, isloading: "", errors: null };
    },
  },
});

export { paddockSlice };
