import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProductCategoryState: {
    productCategory: null,
    isloading: "",
    errors: null,
  },
  updateProductCategoryState: {
    productCategory: null,
    isloading: "",
    errors: null,
  },
  fetchProductCategoriesState: {
    productCategories: [],
    isloading: "",
    errors: null,
  },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState: initialState,
  reducers: {
    addProductCategory: (state) => {
      state.addProductCategoryState.isloading = "processing";
      state.addProductCategoryState.errors = null;
    },
    addProductCategorySuccess: (state, action) => {
      state.addProductCategoryState.isloading = "success";
      state.addProductCategoryState.productCategory = action.payload;
    },
    addProductCategoryFailed: (state, action) => {
      state.addProductCategoryState.isloading = "failed";
      state.addProductCategoryState.errors = action.payload;
    },
    updateProductCategory: (state) => {
      state.updateProductCategoryState.isloading = "processing";
      state.updateProductCategoryState.errors = null;
    },
    updateProductCategorySuccess: (state, action) => {
      state.updateProductCategoryState.isloading = "success";
      state.updateProductCategoryState.productCategory = action.payload;
    },
    updateProductCategoryFailed: (state, action) => {
      state.updateProductCategoryState.isloading = "failed";
      state.updateProductCategoryState.errors = action.payload;
    },
    fetchProductCategories: (state) => {
      state.fetchProductCategoriesState.isloading = "processing";
      state.fetchProductCategoriesState.errors = null;
    },
    fetchProductCategoriesSuccess: (state, action) => {
      console.log("fetchProductsSuccess action is ", action);
      state.fetchProductCategoriesState.isloading = "success";
      state.fetchProductCategoriesState.productCategories = action.payload;
    },
    fetchProductCategoriesFailed: (state, action) => {
      state.fetchProductCategoriesState.isloading = "failed";
      state.fetchProductCategoriesState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddProductCategory(state) {
      state.addProductCategoryState = {
        productCategory: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateProductCategory(state) {
      state.updateProductCategoryState = {
        productCategory: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { productCategorySlice };
