import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addPurchase as addPurchaseApi,
  updatePurchase as updatePurchaseApi,
  fetchPurchases as fetchPurchasesApi,
} from "../apis/purchaseApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { purchaseSlice } from "../slices/purchaseSlice";

const goatActions = purchaseSlice.actions;

export function* addPurchase(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addPurchaseApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addPurchaseSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Purchases",
      "Goat Purchases Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add purchase";
    yield put(goatActions.addPurchaseFailed(message));
  }
}

export function* updatePurchase(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const { id } = actions.payload;
    delete actions.payload.id;

    const response = yield call(updatePurchaseApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updatePurchaseSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Purchases",
      "Goat Purchases Added Successful!",
    );
  } catch (e) {
    console.log("error e is ", e);
    console.log("error e is ", e.data);
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add purchase";
    yield put(goatActions.updatePurchaseFailed(message));
  }
}

export function* fetchPurchases(actions) {
  try {
    const response = yield call(fetchPurchasesApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchPurchasesSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Purchases",
      "Failed to fetch goat purchases!",
    );
    yield put(goatActions.fetchPurchasesFailed());
  }
}

export function* watchAddPurchase() {
  yield takeLeading(goatActions.addPurchase.type, addPurchase);
}

export function* watchUpdatePurchase() {
  yield takeLeading(goatActions.updatePurchase.type, updatePurchase);
}

export function* watchFetchPurchases() {
  yield takeLatest(goatActions.fetchPurchases.type, fetchPurchases);
}
