import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addBreed as addBreedApi,
  fetchBreeds as fetchBreedsApi,
  updateBreed as updateBreedApi,
} from "./api";
import { OpenNotificationWithIcon } from "../../components/Notifications";
import { getUser, processErrors } from "../../services/api/axiosDefaults";
import { breedSlice } from "./slice";

const greedActions = breedSlice.actions;

export function* addBreed(actions) {
  try {
    const { name } = actions.payload;

    const response = yield call(addBreedApi, {
      name,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(greedActions.addBreedSuccess(resp));
    OpenNotificationWithIcon("success", "Add Breed", "Added Successful!");
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add breed";
    yield put(greedActions.addBreedFailed(message));
  }
}

export function* updateBreed(actions) {
  try {
    const { id, name } = actions.payload;

    const response = yield call(updateBreedApi, id, {
      name,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(greedActions.updateBreedSuccess(resp));
    OpenNotificationWithIcon("success", "Update Breed", "Updated Successful!");
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add breed";
    yield put(greedActions.updateBreedFailed(message));
  }
}

export function* fetchBreeds(actions) {
  try {
    const response = yield call(fetchBreedsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      greedActions.fetchBreedsSuccess({
        results: resp && resp.results,
        count: resp && resp.count,
      }),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Breed ",
      "Failed to fetch breeds!",
    );
    yield put(greedActions.fetchBreedsFailed());
  }
}

export function* watchAddBreed() {
  yield takeLeading(greedActions.addBreed.type, addBreed);
}

export function* watchUpdateBreed() {
  yield takeLeading(greedActions.updateBreed.type, updateBreed);
}

export function* watchFetchBreeds() {
  yield takeLatest(greedActions.fetchBreeds.type, fetchBreeds);
}
