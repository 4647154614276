import { call, put, takeLatest, takeLeading } from "redux-saga/effects";
import {
  addGoatCastration as addGoatCastrationApi,
  updateGoatCastration as updateGoatCastrationApi,
  fetchGoatCastrations as fetchGoatCastrationsApi,
} from "../apis/castrationApi";
import { OpenNotificationWithIcon } from "../../../components/Notifications";
import { getUser, processErrors } from "../../../services/api/axiosDefaults";
import { castrationSlice } from "../slices/castrationSlice";

const goatActions = castrationSlice.actions;

export function* addGoatCastration(actions) {
  try {
    console.log("actions.payload is ", actions.payload);
    const response = yield call(addGoatCastrationApi, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.addGoatCastrationSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Castration",
      "Goat Castration Added Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add castration";
    yield put(goatActions.addGoatCastrationFailed(message));
  }
}

export function* updateGoatCastration(actions) {
  try {
    const { id } = actions.payload;
    delete actions.payload.id;
    console.log("actions.payload is ", actions.payload);
    const response = yield call(updateGoatCastrationApi, id, {
      ...actions.payload,
      user: getUser().id,
    });
    console.log("response is ", response);
    const resp = response && response.data;

    yield put(goatActions.updateGoatCastrationSuccess(resp));
    OpenNotificationWithIcon(
      "success",
      "Goat Castration",
      "Goat Castration Updated Successful!",
    );
  } catch (e) {
    const message =
      processErrors(e && e.response && e.response.data) ||
      "Failed to add castration";
    yield put(goatActions.updateGoatCastrationFailed(message));
  }
}

export function* fetchGoatCastrations(actions) {
  try {
    const response = yield call(fetchGoatCastrationsApi, {
      ...actions.payload,
    });
    const resp = response && response.data;
    yield put(
      goatActions.fetchGoatCastrationsSuccess(
        resp && resp.results,
        resp && resp.count,
      ),
    );
  } catch (error) {
    OpenNotificationWithIcon(
      "error",
      "Fetch Goat castrations",
      "Failed to fetch goat castrations!",
    );
    yield put(goatActions.fetchGoatCastrationsFailed());
  }
}

export function* watchAddGoatCastration() {
  yield takeLeading(goatActions.addGoatCastration.type, addGoatCastration);
}

export function* watchUpdateGoatCastration() {
  yield takeLeading(
    goatActions.updateGoatCastration.type,
    updateGoatCastration,
  );
}

export function* watchFetchGoatCastrations() {
  yield takeLatest(goatActions.fetchGoatCastrations.type, fetchGoatCastrations);
}
