import { apiAuthenticatedRequest } from "../../services/api/apiRequest";
import { PADDOCKS_ENDPOINT } from "../../services/api/urls";

export const addPaddock = (data) => {
	return apiAuthenticatedRequest("POST", PADDOCKS_ENDPOINT, data);
};

export const updatePaddock = (id, data) => {
	return apiAuthenticatedRequest("PUT", `${PADDOCKS_ENDPOINT}${id}/`, data);
};

export const fetchPaddocks = (query) => {
	console.log("query is ", query);
	let search = query && query.search;
	let limit = query && query.limit;
	let offset = query && query.offset;
	let user = query && query.user;
	let farm = query && query.farm;

	return apiAuthenticatedRequest(
		"GET",
		`${PADDOCKS_ENDPOINT}?
		${search ? `&search=${search}` : ""}
		${user ? `&farm__user__id=${user}` : ""}
		${farm ? `&farm=${farm}` : ""}
		${offset ? `&offset=${offset}` : ""}
		${limit ? `&limit=${limit}` : ""}`,
	);
};
