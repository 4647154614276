import React from "react";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { authSlice } from "../../containers/Auth/slice";

import { getUser } from "../../services/api/axiosDefaults";

const authActions = authSlice.actions;

const { Header } = Layout;

const menu = (dispatch) => (
  <Menu>
    <Menu.Item key="2">
      <a
        onClick={() => {
          console.log("clicked menu");
          dispatch(authActions.logout());
        }}
      >
        Logout
      </a>
    </Menu.Item>
  </Menu>
);

const LayoutHeader = ({ collapsed, toggle }) => {
  const dispatch = useDispatch();
  return (
    <Header
      className="site-layout-sub-header-background"
      style={{ padding: 0 }}
    >
      {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
        className: "trigger",
        onClick: toggle,
      })}
      <div
        style={{
          position: "absolute",
          top: "-1px",
          right: "20px",
          fontSize: "14px",
        }}
      >
        <Dropdown overlay={menu(dispatch)} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <span style={{ marginRight: "5px" }}>
              Hi {getUser().first_name},
            </span>
            <Avatar icon={<UserOutlined />} />
            <DownOutlined style={{ marginLeft: "2px" }} />
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default LayoutHeader;
