import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addNewEventState: { newevent: null, isloading: "", errors: null },
  updateNewEventState: { newevent: null, isloading: "", errors: null },
  fetchNewEventsState: { newevents: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const neweventSlice = createSlice({
  name: "newevent",
  initialState: initialState,
  reducers: {
    addNewEvent: (state) => {
      state.addNewEventState.isloading = "processing";
      state.addNewEventState.errors = null;
    },
    addNewEventSuccess: (state, action) => {
      state.addNewEventState.isloading = "success";
      state.addNewEventState.newevent = action.payload;
    },
    addNewEventFailed: (state, action) => {
      state.addNewEventState.isloading = "failed";
      state.addNewEventState.errors = action.payload;
    },
    updateNewEvent: (state) => {
      state.updateNewEventState.isloading = "processing";
      state.updateNewEventState.errors = null;
    },
    updateNewEventSuccess: (state, action) => {
      state.updateNewEventState.isloading = "success";
      state.updateNewEventState.newevent = action.payload;
    },
    updateNewEventFailed: (state, action) => {
      state.updateNewEventState.isloading = "failed";
      state.updateNewEventState.errors = action.payload;
    },
    fetchNewEvents: (state) => {
      state.fetchNewEventsState.isloading = "processing";
      state.fetchNewEventsState.errors = null;
    },
    fetchNewEventsSuccess: (state, action) => {
      console.log("fetchNewEventsSuccess action is ", action);
      state.fetchNewEventsState.isloading = "success";
      state.fetchNewEventsState.newevents = action.payload;
    },
    fetchNewEventsFailed: (state, action) => {
      state.fetchNewEventsState.isloading = "failed";
      state.fetchNewEventsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddNewEvent(state) {
      state.addNewEventState = {
        newevent: null,
        isloading: "",
        errors: null,
      };
    },
    resetUpdateNewEvent(state) {
      state.updateNewEventState = {
        newevent: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { neweventSlice };
