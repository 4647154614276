import React from "react";
import { Layout } from "antd";

const { Content } = Layout;

const Container = ({ children }) => {
  return (
    <Content
      className="site-layout-background"
      style={{
        margin: "24px 20px",
        padding: 24,
        minHeight: 800,
      }}
    >
      {children}
    </Content>
  );
};

export default Container;
