import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAccountsState: { account: null, isloading: "", errors: null },
  updateAccountsState: { account: null, isloading: "", errors: null },
  fetchAccountsState: { accounts: [], isloading: "", errors: null },
  filters: {
    offset: 0,
    limit: 10,
  },
};

const accountsSlice = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {
    addAccounts: (state) => {
      state.addAccountsState.isloading = "processing";
      state.addAccountsState.errors = null;
    },
    addAccountsSuccess: (state, action) => {
      state.addAccountsState.isloading = "success";
      state.addAccountsState.account = action.payload;
    },
    addAccountsFailed: (state, action) => {
      state.addAccountsState.isloading = "failed";
      state.addAccountsState.errors = action.payload;
    },
    updateAccounts: (state) => {
      state.updateAccountsState.isloading = "processing";
      state.updateAccountsState.errors = null;
    },
    updateAccountsSuccess: (state, action) => {
      state.updateAccountsState.isloading = "success";
      state.updateAccountsState.account = action.payload;
    },
    updateAccountsFailed: (state, action) => {
      state.updateAccountsState.isloading = "failed";
      state.updateAccountsState.errors = action.payload;
    },
    fetchAccounts: (state) => {
      state.fetchAccountsState.isloading = "processing";
      state.fetchAccountsState.errors = null;
    },
    fetchAccountsSuccess: (state, action) => {
      console.log("fetchAccountsSuccess action is ", action);
      state.fetchAccountsState.isloading = "success";
      state.fetchAccountsState.paddocks = action.payload;
    },
    fetchAccountsFailed: (state, action) => {
      state.fetchAccountsState.isloading = "failed";
      state.fetchAccountsState.errors = action.payload;
    },
    resetFilters(state) {
      state.filters = {
        offset: 0,
        limit: 10,
      };
    },
    resetAddAccounts(state) {
      state.addAccountsState = { accounts: null, isloading: "", errors: null };
    },
    resetUpdateAccounts(state) {
      state.updateAccountsState = {
        accounts: null,
        isloading: "",
        errors: null,
      };
    },
  },
});

export { accountsSlice };
